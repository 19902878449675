import React, { useEffect, useState } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'
import { getAllAsset, updateAsset } from '../../Hooks/UseBackendAction/UseGetController';
import toast, { Toaster } from 'react-hot-toast';
import {  style,iconTheme,position } from '../../Hooks/UseToast';
import { ValidateUpdateToken ,countryList, validateBankDetails} from '../../Hooks/UseValidation';
import { backurl } from '../../config/env';
import { UseUpdateBank } from '../../Hooks/UseBackendAction/UseAdmin';
const initialFormValue  = {
    'tokenAddress' : '',
'symbol':'',
'name':'',
'image':'',
'type':''
}
const FaqListModal = (props) =>{
  

//state
   const[faqlist, setFaqList] = useState(true)
   const[updatetoken, setUpdateToken]=useState(initialFormValue)
   const[errors, setError] = useState({})
   const [Country,setCountry] = useState(props?.data?.CountryList)
   const [currency,setcurrency] = useState(props?.data?.currency)
   const [holdername,setholdername] = useState(props?.data?.holdername)
   const [bankname,setbankname] = useState(props?.data?.bankname)
   const [Accountnumber,setAccountnumber] = useState(props?.data?.Accountnumber)
   const [IFSCcode,setIFSCcode] = useState(props?.data?.IFSCcode)
   const [CountryList,setcountryList] = useState(countryList)
   const [bankaddress,setbankaddress] = useState(props?.data?.bankaddress)
   const [bankcity,setbankcity] = useState(props?.data?.bankcity)
   const [AccountType,setAccountType] = useState(props?.data?.AccountType)
   const{ tokenAddress, symbol, name, image, type}= updatetoken;

//function

const Onsubmit = async() =>{
    var bankdetails ={
      holdername : holdername,
      currency:currency,
      bankname: bankname,
      Accountnumber:Accountnumber,
      IFSCcode:IFSCcode,
      CountryList:Country,
      bankaddress:bankaddress,
      bankcity:bankcity,
      AccountType : AccountType,
       time:Date.now()
  }
  
   const { errors, isValid } = await validateBankDetails(bankdetails);
   if (!isValid) {
    
    setError(errors)
   }else{ 
   
    UseUpdateBank(bankdetails)}
   
  }


useEffect(()=>
{
   console.log("props",props);
},[props])

   return(
    <>
    <Modal size="md"   show={faqlist} onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>

      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll'>
       
      <div className='mb-4 themeinput'>
        <h5 className='headfont mb-4'>Update Bank Details</h5>

        <div className='w-full mb-3'>
                
                <div className=''>
                <p className='mb-2' >Select Currency</p>
                <select className='form-control'
                    value={currency}
                    onChange={(e) => { setcurrency (e.target.value)}}>
                      <option >Select Currency</option>
                      <option value="EUR">EURO</option>
                      <option value="USD">USD</option>
                      <option value="ZAR">ZAR</option>
                      <option value="GBP">GBP</option>
                                          
                    </select>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.currency}</span>
               
                <p className='mb-2' >Holder Name</p>
                <div className='themeinput'>
                   <input type='text' className='form-control'
                    name='tokenAddress' 
                    
                    maxLength={45}
                    value={holdername} onChange={(e) => { setholdername(e.target.value) }}></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.holdername}</span>
            </div>
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Bank Name</p>
                <div className='themeinput'>
                    <input type='text' name='symbol' 
                    className='form-control' 
                      maxLength={45}
                      readOnly
                     value={bankname} onChange={(e) => { setbankname(e.target.value) }} ></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.bankname}</span>
            </div>
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Account Number</p>
                <div className='themeinput'>
                    <input type='text' name='name' className='form-control' 
                     maxlength="30"
                    value={Accountnumber} onChange={(e) => { setAccountnumber(e.target.value) }}></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.Accountnumber}</span>
            </div>
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Bank Code</p>
                <div className='themeinput'>
                    <input type='text' name='name' className='form-control' 
                     maxLength={45}value={IFSCcode} onChange={(e) => { setIFSCcode(e.target.value) }}></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.IFSCcode}</span>
            </div>
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Bank Country</p>
                <select className='form-control'
                    value={Country}
                    maxLength={45}
                    onChange={(e) => { setCountry (e.target.value)}}>
                      <option >Select Country</option>
                                           {CountryList && CountryList.map((item) => 
                            
                                <option value={item.name}>{item.name}</option>
                                          )}
                    </select>
                <span className="text-danger f-12 d-block text-left">{errors.CountryList}</span>
            </div>
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Bank Address</p>
                <div className='themeinput'>
                    <input type='text' name='name' className='form-control' 
                     maxLength={45} value={bankaddress} onChange={(e) => { setbankaddress(e.target.value) }}></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.bankaddress}</span>
            </div>
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Bank City</p>
                <div className='themeinput'>
                    <input type='text' name='name' className='form-control' 
                     maxLength={45} value={bankcity} onChange={(e) => { setbankcity(e.target.value) }} ></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.bankcity}</span>
            </div>
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Account Type</p>
                <div className='themeinput'>
                    <input type='text' name='name' className='form-control' 
                     maxLength={45} value={AccountType} onChange={(e) => { setAccountType(e.target.value) }} ></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.AccountType}</span>
            </div>
   <div className='text-end'>
       <button className='btn btn-theme mt-4 ' onClick={()=>Onsubmit()}>Update</button>
</div>




   
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default FaqListModal