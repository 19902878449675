import React from 'react';

import Header from '../Header';
import Sidebar from '../Sidebar';

import DataTable from 'react-data-table-component';



export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};

const OrderHistory = () =>{
  
  const columns = [
    {
      name: "Date",
      selector: (vals) => vals.date,
      sortable: true
    },
    {
      name: "userId",
      selector: (vals) => vals.userid,
      sortable: true,
      minWidth:"250px"
    },
    {
      name: "Base currency",
       selector: (vals) => vals.email,
      sortable: true
    },
    {
      name: "Quote Currency",
      selector: (vals) => vals.google,
      sortable: true
    },
    {
      name: "Type",
      selector: (vals) => vals.btn,
      sortable: true
    },
    {
        name: "Side",
        selector: (vals) => vals.col1,
        sortable: true
    },
    {
        name: "Executed Price",
        selector: (vals) => vals.col2,
        sortable: true,
        minWidth:"200px"
    },
    {
        name: "Price",
        selector: (vals) => vals.col3,
        sortable: true
    },
    {
        name: "Executed",
        selector: (vals) => vals.col4,
        sortable: true
      },
      {
        name: "Total",
        selector: (vals) => vals.col5,
        sortable: true
      },
      {
        name: "Order Status",
        selector: (vals) => vals.col6,
        sortable: true,
        minWidth:"120px"
      },
     
     
   
      
   
]  
const vals = [
  {
    date:"2022-09-27 17:43",
    userid: "62a1bc9fa03b4412b31af6ff",
    email: "BTC",
    google: "USDT",
    btn: "market",
    col1:"buy",
    col2:"800.00000000",
    col3:"Market price",
    col4:"0.5",
    col5:"400",
    col6:"completed"
  },

]

return(
<>
 <div className='history dashboard transsec'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
            <h3 className='headfont '>Spot Order History</h3>
            </div>
            <div className='tabtable mt-5 potfolio box darkbox noshadow'>
                <h4 className='headfont'>Order History</h4>
                <div className='mb-4'>
                    <button className='btn btn-theme mt-3 me-2'>Download(PDF)</button>
                    <button className='btn btn-theme mt-3 me-2'>Download(XLS)</button>
                    <button className='btn btn-theme mt-3 me-2'>Download(CSV)</button>
                </div>
                <div className=' themeinput mb-3'>
                    <input type="text" placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
                </div>
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={vals}/>
            </div>
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default OrderHistory