export const  style = {
      
                        
    minWidth: '300px',
    minHeight: '55px',
    border: '1px solid #040404',
    padding: '16px',
    color: '#4a34cf'
    
}

export const iconTheme = {
    primary: '#4a34cf',
    secondary: '#040404',
}

export const  position= {
    position: "bottom-center"
}