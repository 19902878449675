import React, { useState } from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import logo from "../../src/Assets/Images/Landing-page/logo.png"
import { UseAdminLogin } from '../Hooks/UseBackendAction/UseAdmin';

const AdminLogin = () =>{

    const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [passwordeye, setpasswordeye] = useState(false)

  const AdminLogin =async(data) =>{
    await UseAdminLogin(data)
    // await sleep(1000)
   
    
}
const onAdminLoginSubmit = async () => {

   const AdminLoginData = {
        
        email:Email,
        password: Password,
     };
    
     await AdminLogin(AdminLoginData)
    
};


const showPassword = (e) => {

    var e = document.getElementById("Password");
    if (e.type === "password") {
        setpasswordeye(true)
        e.type = "text";
    } else {
        setpasswordeye(false)
        e.type = "password";
    }
};
   return(
    <>
        <div className='login'>
            <div className='container'>

                <div className='row justify-content-center align-items-center'>
                    <div className='col-lg-4 col-md-6 col-sm-8 col-10 box darkbox'>
                    <div className="text-center mb-3"><img src={logo}/></div>
                    <div className='mb-4 logins p-4 br-6'>

                        <h3 className='headfont mb-4  text-center'>Login</h3>
                        <div className='logininput w-full mb-3'>
                                <label className='mb-2'>Email</label>
                                <input value={Email} required
                                 onChange={(e) => { setEmail(e.target.value) }} placeholder='Enter Email' className='form-control'/>
                        </div>
                        <div className='logininput w-full mb-3'>
                       
                        <InputGroup className="mb-3">
        <Form.Control
        text="password"
        placeholder='Enter Password' 
        required
        type="password"
        id="Password"
        onChange={(e) => { setPassword(e.target.value) }}
       className='form-control'/>
        {!passwordeye ?  <InputGroup.Text id="basic-addon2"><i className='fa fa-eye-slash 'onClick={showPassword}></i></InputGroup.Text>:
        <InputGroup.Text id="basic-addon2"><i className='fa fa-eye' onClick={showPassword}></i></InputGroup.Text>}
      </InputGroup>
                        {/* <div className='wizardinput w-full mb-3'>
                                <label className='mb-2'>Password</label>
                                 <input id="Password"
                                 value={Password}
                                onChange={(e) => { setPassword(e.target.value) }} placeholder='Enter Password' className='form-control'/>
                                      </div>
                                      {!passwordeye ?  <InputGroup.Text id="basic-addon2"><i className='fa fa-eye-slash'onClick={showPassword}></i></InputGroup.Text>:
        <InputGroup.Text id="basic-addon2"><i className='fa fa-eye' onClick={showPassword}></i></InputGroup.Text>} */}
                   
         </div>               


                        <div className='w-full mb-3'>
                            <button className='btn btn-theme w-full' onClick={()=> onAdminLoginSubmit()}>Login</button>
                        </div>
                    </div>
                </div>
                
                </div>
            </div>
        </div>
   </>
   )
}

export default AdminLogin