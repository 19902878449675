import React, { useState } from 'react';
import {Modal} from 'react-bootstrap'

import DataTable from 'react-data-table-component';




const Refferal = (props) =>{
   const[refferal, setRefferal] = useState(true)


   const columns = [
    {
      name: "Email",
       selector: (vals) => vals.img,
      sortable: true
    },
    {
        name: "Date",
        selector: (vals) => vals.token,
        sortable: true
      },
      {
        name: "Amount",
        selector: (vals) => vals.token,
        sortable: true
      },
   
      
   
]  
const vals = [
  {
    img:"example@gmail.com",
    time: "12-12-2022 12:15",
    token: "$15.15",

  },


]


   return(
    <>
    <Modal size="xl" show={refferal}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll'>
      <div className='mb-4 '>
      <div className='d-sm-flex mt-3 jc-between align-items-center'>
       
       <h5 className='headfont'>Refferal ID: 457715</h5>
       <div className='themeinput mb-3'>
           <input type="text" placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
       </div> 
</div>


<div className=' tabtable  potfolio mt-4 '>
   
   
    
<DataTable pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={vals}/>
</div>



   
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default Refferal