import React, { useEffect, useState } from 'react';

import Header from './Header';
import Sidebar from './Sidebar';


import DataTable from 'react-data-table-component';
import FaqListModal from './Modals/FaqListModal';
import AddFaq from './Modals/AddFaq';
import { getAllAsset, updateEnabled } from '../Hooks/UseBackendAction/UseGetController';
import { searchedToken } from '../Hooks/UseValidation';
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../Hooks/UseToast';
import { backurl } from '../config/env';
export const data = {
  labels: ['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options: {
    cutout: "50%",
    plugins: {
      legend: {
        display: true,
        margin: 10,
        position: "right",
        align: "center",
        labels: {
          usePointStyle: true,
          padding: 20,
          color: "#fff"
        },
      },
    },
  }

};



const FaqList = () => {

  //state
  const [faqlist, setFaqList] = useState(false)
  const [addfaq, setAddFaq] = useState(false)
  const [record, setRecord] = useState([]);
  const [currentdata, setCurrentdata] = useState({})
  const [searchSymbolData, setSearchSymbolData] = useState([])
  const [isSearch, setisSearch] = useState(false)
  const [Search, setSearch] = useState("")
  const [state, setState] = useState(true)

  const getIsEnabled = async (tokenAddress, showEye) => {

    var data = await updateEnabled({ showEye, tokenAddress });

    if (data.data.data.isEnabled == true) {

      toast.success("Enabled", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
      getAssetData()
    } else {
      toast.success("Disabled", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
      getAssetData()
    }
  }

  const columns = [
    {
      name: "Asset Symbol",
      selector: (record) => record.displayname,
      sortable: true,
      minWidth: "100px"
    },
    {
      name: "Asset Name",
      selector: (record) => record.name,
      sortable: true,
      minWidth: "100px"
    },
    {
      name: "Asset Type",
      selector: (record) => record.type,
      sortable: true,
      minWidth: "150px"
    },
    {
      name: "Asset Address",
      selector: (record) => (record.tokenAddress != "" ?  record.tokenAddress.substring(0, 5) + "..." + record.tokenAddress.substring(35, 42) : "-"),
      sortable: true,
      minWidth: "250px"
    },
    {
      name: "Token Decimal",
      selector: (record) => (record.decimal != ""?record.decimal:"-"),
      sortable: true,
      minWidth: "250px"
    },
    {
      name: "Asset Image",
      selector: (record) => (<><img src={backurl + `/Images/${record.logoURI}`} /></>),
      sortable: true,
      minWidth: "100px"
    },
    {
      name: "Action",
      selector: (record) => (<div className='d-flex gap-1'>
        <button disabled={!record?.isEnabled} className='btn btn-theme iconbtn' onClick={() => { setFaqList(true); setCurrentdata(record) }}>
          <span className='fa fa-edit'></span>
        </button>
        {record?.isEnabled == false ?
          <button className='btn btn-theme iconbtn' onClick={() => { getIsEnabled(record?.tokenAddress, !record?.isEnabled) }}><span className='fa fa-eye-slash'></span>
          </button>
          :
          <button className='btn btn-theme iconbtn' onClick={() => { getIsEnabled(record?.tokenAddress, !record?.isEnabled); }}><span className='fa fa-eye'></span>
          </button>} </div>),
      sortable: true,
      maxWidth: "80px"
    },

  ]

  //function
  const getAssetData = async () => {
    var data = await getAllAsset();
    setRecord(data.success == true ? data.data : [])
  };





  const handlefilter = async (e) => {
    if ((e.target.value).length > 0) {
      const searchData = await searchedToken(record, e.target.value);

      setSearchSymbolData(searchData)
      setisSearch(true)
    }
    else {
      setisSearch(false)
    }
  }

  useEffect(() => {
    getAssetData();
  }, [])


  return (
    <>

      {addfaq && <AddFaq record={() => getAssetData()} onDismiss={() => setAddFaq(false)} />}
      {faqlist && <FaqListModal data={currentdata} record={() => getAssetData()} onDismiss={() => setFaqList(false)} />}
      <div className='history dashboard transsec'>
        <Header />
        <div className='page'>
          <div className='sidebar'><Sidebar /></div>
          <div className='content trans'>
            <div className='container-fluid container-theme p-4'>
              <div className='mb-4'>
                <h3 className='headfont '>VIEW ASSET</h3>
              </div>
              <div className='tabtable mt-5 potfolio box darkbox noshadow'>
                <div className='d-flex jc-between'>  <h4 className='headfont'>Asset List</h4>
                  <button className='btn btn-theme' onClick={() => setAddFaq(true)}><span className='fa fa-plus me-2'></span>Add Tokens</button>
                </div>

                <div className='themeinput mb-3 mt-4'>
                  <input type="text" onChange={(e) => { setSearch(e.target.value); handlefilter(e) }} placeholder="Search by Symbol..." className='form-control mw-300 ms-auto' />
                </div>

                {!isSearch ?
                  <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={record} /> :

                  <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={searchSymbolData} />}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FaqList