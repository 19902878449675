import React, { useState,useEffect } from 'react';
import {Navbar,Dropdown,Nav,NavDropdown ,NavLink, DropdownButton} from 'react-bootstrap'

import { Link } from 'react-router-dom';
import logo from '../Assets/Images/logo.svg'
import Sidebar from './Sidebar';
import {style,iconTheme,position} from "../Hooks/UseToast"
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import prof from '../Assets/Images/prof.png'
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { logoutUser } from '../Hooks/UseBackendAction/UseAdmin';

const Header = () =>{
  const[sidebar, setSidebar] = useState(true)
  const [adminusername,setadminusername] = useState(sessionStorage.getItem("adminusername"))
  const navigate = useNavigate();
  const showsidebar = () =>{
    setSidebar(!sidebar)
  }

  useEffect(() =>{
    
    if (!sessionStorage.AToken || sessionStorage.AToken == null || sessionStorage.AToken == " ") {
      toast.error("Go to Login !",{
        position:position.position,
        style:style,
        iconTheme: iconTheme,
      })
      //  sleep(1000)
      navigate("/login")
    }
},[])

const onLogoutClick = async() => {
   
  
  await logoutUser();
};
  const title = <><span className='fa fa-user ms-2 headicon'></span></>
  const mobtitle = <span className='fa fa-user headicon'></span>
    return(
    <>
     <Navbar collapseOnSelect expand="md"  variant="dark" className='h-55'>

        <Navbar.Brand className='logobg py-2' href="#home"><img src={logo} className='logo_resize' /></Navbar.Brand>


        <div className='d-flex mobileicons'>

        {/* <Nav.Link href="#deets" className='px-0  notification'>
              
              <Dropdown className='notify' drop="down"  align="end">
                <Dropdown.Toggle variant="success"  id="dropdown-basic">
                   Settings <span className='fa fa-chevron-down f-12'></span>
                </Dropdown.Toggle>

                <Dropdown.Menu className=''>
               
                  <Dropdown>
                  <NavLink to="/transaction">Profile</NavLink>
                  </Dropdown>
                  <Dropdown.Divider />
                  <Dropdown>
                  <NavLink to="/transaction">Reset password</NavLink>
                  </Dropdown>
                  <Dropdown.Divider />
                  <Dropdown>
                  <NavLink to="/transaction">Settings</NavLink>
                  </Dropdown>
                  <Dropdown.Divider />
                  <Dropdown>
                  <NavLink to="/transaction">Login History</NavLink>
                  </Dropdown>
                  <Dropdown.Divider />
                  <Dropdown>
                  <NavLink to="/transaction">Google 2FA</NavLink>
                  </Dropdown>
                </Dropdown.Menu>

               

              </Dropdown>
            
             </Nav.Link> */}

        <div> <NavLink to="/transaction"><span className='fa fa-sign-out showin767'></span> <span className='logout'>Logout</span> </NavLink>
          </div>    
            <button onClick={() => showsidebar()} className="showin767 togglebtn" >
             <span className='fa fa-bars pe-3 text-light'></span></button>
        </div>

        <Navbar.Collapse id="responsive-navbar-nav" className={sidebar ? 'pe-3 h-55' : 'd-block mobilemenu pt-md-0 h-55 pt-4 pe-3'}>
       
          <Nav className="ms-auto align-items-md-center">
{/*          
            <Nav.Link href="#deets" className='dnone-767 mx-2 notification'>
              
              <Dropdown className='notify' drop="down"  align="end">
                <Dropdown.Toggle variant="success" className='' id="dropdown-basic">
                   Settings <span className='fa fa-chevron-down f-12'></span>
                </Dropdown.Toggle>

                <Dropdown.Menu className=''>
               
                  <Dropdown>
                  <NavLink to="/transaction">Profile</NavLink>
                  </Dropdown>
                  <Dropdown.Divider />
                  <Dropdown>
                  <NavLink to="/transaction">Reset password</NavLink>
                  </Dropdown>
                  <Dropdown.Divider />
                  <Dropdown>
                  <NavLink to="/transaction">Settings</NavLink>
                  </Dropdown>
                  <Dropdown.Divider />
                  <Dropdown>
                  <NavLink to="/transaction">Login History</NavLink>
                  </Dropdown>
                  <Dropdown.Divider />
                  <Dropdown>
                  <NavLink to="/transaction">Google 2FA</NavLink>
                  </Dropdown>
                </Dropdown.Menu>

               

              </Dropdown>
            
             </Nav.Link> */}
<p className='mt-3'>Welcome {adminusername ? adminusername  : "Admin"} </p>
<NavDropdown className='dnone-767 headdrop' title={title} id="collasible-nav-dropdown" >
              <NavDropdown.Item onClick={()=>onLogoutClick()}>Logout</NavDropdown.Item>
             
            </NavDropdown>
          
          
          </Nav>

          <div  className="showin767 mobilesidebar"><Sidebar/></div> 
        
       
        </Navbar.Collapse>
     
    </Navbar>
    </>
    )
}
export default Header