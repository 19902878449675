import React,{useEffect, useState} from 'react';
import { Button,Tabs, Tab,Form , Dropdown, InputGroup} from 'react-bootstrap';
import {NavLink} from 'react-router-dom'

import Header from './Header';
import Sidebar from './Sidebar';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import DonutChart from 'react-donut-chart';

import edit from '../Assets/Images/edit.svg'
import DataTable from 'react-data-table-component';
import EditProfile from './Modals/EditProfile';
import Preference from './Modals/Preference';
import faker from 'faker';

import coin1 from '../Assets/Images/coin1.png'
import coin2 from '../Assets/Images/coin2.png'
import coin3 from '../Assets/Images/coin3.png'
import coin4 from '../Assets/Images/coin4.png'
import coin5 from '../Assets/Images/coin5.png'

import bcoin1 from '../Assets/Images/bcoin1.png'
import bcoin2 from '../Assets/Images/bcoin2.png'
import bcoin3 from '../Assets/Images/bcoin3.png'
import bcoin4 from '../Assets/Images/bcoin4.png'
import { getAllDepositProof, getAllFeeInfo, getAlluserInfo, getAllWithdrawrequest, getDepositisApproved, getKYCisApproved, getSolanaBalance, getSolanaBalanceToken, getWithdrawisApproved } from '../Hooks/UseBackendAction/UseGetController';
// import { getAllDepositProof, getAllFeeInfo, getAlluserInfo, getAllWithdrawrequest, getDepositisApproved, getKYCisApproved, getWithdrawisApproved } from '../Hooks/UseBackendAction/UseGetController';

import { UseUpdateFeeAmount } from '../Hooks/UseBackendAction/UseAdmin';





ChartJS.register(
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);




export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'  ,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];

export const data = {
  labels,
  datasets: [
    {
      // label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: 'yellow',
      backgroundColor: 'yellow',
    },
    {
      // label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: '#fff',
      backgroundColor: '#fff',
    },
  ],
};

const Dashboard = () =>{
  
  const columns = [
    {
      name: "",
       selector: (vals) => vals.img,
      sortable: true
    },
 
      {
        name: "",
        selector: (vals) => vals.count,
        sortable: true
      },
      {
        name: "",
        selector: (vals) => vals.time,
        sortable: true
      },
      {
        name: "",
        selector: (vals) => vals.btn,
        sortable: true
      },
      {
        name: "",
        selector: (vals) => vals.stat,
        sortable: true
      }
      
   
]  
const vals = [
  {
    img:<><img src={coin1}  className="me-2"/> BSC</>,
    time: "10:24:05 AM",
  
    token: "BSC",
    count: "+53.4684$",
    btn: <Button variant='outline-success'>Completed</Button>,
    stat: <span className=' green'><i className='fa fa-arrow-down green  text-white'></i> </span>
  },
  {
    img:<><img src={coin2}  className="me-2"/> BSC </>,
    time: "10:24:05 AM",
    token: "BSC",
    count: "+53.4684$",
    btn: <Button variant='outline-danger'>Canceled</Button>,
    stat: <span className=' red'><i className='fa fa-arrow-down red  text-white'></i></span>
  },
  {
    img:<><img src={coin3}  className="me-2"/> BSC </>,
    time: "10:24:05 AM",
    token: "BSC",
    count: "+53.4684$",
    btn: <Button variant='outline-secondary'>Pending</Button>,
    stat: <span className=' gray'><i className='fa fa-arrow-down gray text-white'></i></span>
  },
  {
    img:<><img src={coin3}  className="me-2"/> BSC </>,
    time: "10:24:05 AM",
    token: "BSC",
    count: "+53.4684$",
    btn: <Button variant='outline-secondary'>Pending</Button>,
    stat: <span className=' gray'><i className='fa fa-arrow-down gray text-white'></i></span>
  },
  {
    img:<><img src={coin2}  className="me-2"/> BSC </>,
    time: "10:24:05 AM",
    token: "BSC",
    count: "+53.4684$",
    btn: <Button variant='outline-danger'>Canceled</Button>,
    stat: <span className=' red'><i className='fa fa-arrow-down red  text-white'></i></span>
  },
 

]
const[profile, setProfile ]= useState(false)
const[preference, setPreference ]= useState(false)
const [userLegnth,setuserLengnth] = useState(0)
const [WithdrawLegnth,setWithdrawLegnth] = useState(0)
const [DepositLegnth,setDepositLegnth] = useState(0)
const [KYCLegnth,setKYCLegnth] = useState(0)
const [depositFee,setdepositFee] = useState("")
const [withdrawFee,setwithdrawFee] = useState("")
const [id,setid] = useState("")
const[zarcBSCBalance,setzarcBSCBalance] = useState(0);
const[zarcETHBalance,setzarcETHBalance] = useState(0);
const[zarcSolanaBalance,setzarcSolanaBalance] = useState(0);
const[bnbBalance,setbnbBalance] = useState(0);
const[ethBalance,setethBalance] = useState(0);
const[solanaTokenBalance,setsolanaTokenBalance] = useState(0)


useEffect(() => {
  getData();

 
  

}, [])
const getData = async () => {

  var data = await getSolanaBalance()
  var data1 = await getSolanaBalanceToken()
 
  // console.log("data1",data,data1);
 
 
  setbnbBalance(parseFloat(data?.bscbalance).toFixed(5))
  setethBalance(parseFloat(data?.ethbalance).toFixed(5))
  setsolanaTokenBalance(parseFloat(data?.solana).toFixed(5))
   setzarcBSCBalance(parseFloat(data1?.bsctoken).toFixed(5))
  setzarcETHBalance(parseFloat(data1?.ethtoken).toFixed(5))
  setzarcSolanaBalance(parseFloat(data1?.solana).toFixed(5))






  var data = await getAlluserInfo();
  setuserLengnth(data?.data?.length)
  var withdraw = await getWithdrawisApproved();
 
  setWithdrawLegnth(withdraw.success == true ? withdraw?.data?.length : 0)

  var deposit = await getDepositisApproved();
  
  setDepositLegnth(deposit.success == true ? deposit?.data?.length : 0)
   
  var KYC = await getKYCisApproved();
  
  setKYCLegnth(KYC.success == true ? KYC?.data?.length : 0)

  var feedata =  await getAllFeeInfo();
 
  setdepositFee(feedata?.data[0]?.depositFee);
  setwithdrawFee(feedata?.data[0]?.withdrawFee);
  setid(feedata?.data[0]?._id);

};



const onSubmit = async () => {

  const data = {

    depositFee: depositFee,
    withdrawFee: withdrawFee,
    id: id,
     
  };

      await UseUpdateFeeAmount(data)
  
};
return(
<>
 <div className='dashboard dashsec history'>
    <Header />
   <div className='page'>
    {profile && <EditProfile onDismiss={() => setProfile(false)}/>}
    {preference && <Preference onDismiss={() => setPreference(false)} />}
      <div className='sidebar'><Sidebar /></div>
      <div className='content'>
         <div className='container-fluid container-theme p-4'>
        
            <h3 className="headfont mb-sm-5">Dashboard</h3>
            <div className='row dashbox '>
            <div className='col-lg-3 col-sm-6 my-sm-2 my-4'>
                    <div className='hoverbox box ps-4 br-6'>
                        <h5 className=' headfont'>Users</h5>
                        <p> <span className='lightgreen'> {userLegnth ? userLegnth : 0}</span></p>
                         <div> <a href='/user' className='btn btn-theme'>Go to User</a></div>
                    </div>
                 
                </div>
                <div className='col-lg-3 col-sm-6 my-sm-2 my-4'>
                    <div className='hoverbox box ps-4 br-6'>
                        <h5 className=' headfont'>Withdraw Request</h5>
                        <p> <span className='lightgreen'>{WithdrawLegnth ? WithdrawLegnth : 0}</span></p>
                         <div> <a href='/withdraw' className='btn btn-theme'>Go to withdraw</a></div>
                    </div>
                   
                </div>
                <div className='col-lg-3 col-sm-6 my-sm-2 my-4'>
                    <div className='hoverbox box ps-4 br-6'>
                        <h5 className=' headfont'>Deposit Request</h5>
                        <p> <span className='lightgreen'> {DepositLegnth ? DepositLegnth : 0}</span></p>
                      <div> <a href='/deposit' className='btn btn-theme'>Go to Deposit</a></div> 
                    </div>
                   

                </div>
                <div className='col-lg-3 col-sm-6 my-sm-2 my-4'>
                    <div className='hoverbox box ps-4 br-6'>
                        <h5 className=' headfont'>KYC Request</h5>
                        <p> <span className='lightgreen'> {KYCLegnth ? KYCLegnth : 0}</span></p>
                      <div> <a href='/kyc' className='btn btn-theme'>Go to KYC</a></div> 
                    </div>
                   

                </div>

                <div className='col-lg-3 col-sm-6 my-sm-2 my-4'>
                    <div className='hoverbox box ps-4 br-6'>
                        <h5 className=' headfont'>Wallet Balance(ZARC)</h5>
                        <p> <span className='lightgreen'><b> {zarcBSCBalance ? zarcBSCBalance : 0}  BSC</b></span></p>
                        <p> <span className='lightgreen'><b> {zarcSolanaBalance ? zarcSolanaBalance : 0} SOLANA</b></span></p> 

                           
                        <p> <span className='lightgreen'><b> {zarcETHBalance ? zarcETHBalance : 0}  ETH</b></span></p>

{/* <p> <span className='lightgreen'><b> 0  BSC</b></span></p>
                        <p> <span className='lightgreen'><b> 0  SOLANA</b></span></p>

                           
                        <p> <span className='lightgreen'><b> 0  ETH</b></span></p> */}
                        
                        
                         {/* <div> <a href='/user' className='btn btn-theme'>Go to User</a></div> */}
                    </div>
                 
                </div>

                <div className='col-lg-3 col-sm-6 my-sm-2 my-4'>
                    <div className='hoverbox box ps-4 br-6'>
                        <h5 className=' headfont'>Wallet Balance(Crypto)</h5>
                        
                         <p> <span className='lightgreen'><b> {bnbBalance ? bnbBalance : 0} BNB</b></span></p>
                        <p> <span className='lightgreen'><b> {solanaTokenBalance ? solanaTokenBalance : 0}  SOLANA</b></span></p>

                        <p> <span className='lightgreen'><b> {ethBalance ? ethBalance : 0} ETH</b></span></p>

                        {/* <p> <span className='lightgreen'><b>0 BNB</b></span></p>
                        <p> <span className='lightgreen'><b> 0 ETH</b></span></p>
                        <p> <span className='lightgreen'><b> 0 SOLANA</b></span></p> */}

                        
                         {/* <div> <a href='/user' className='btn btn-theme'>Go to User</a></div> */}
                    </div>
                 
                </div>
             
            </div>

            {/* <div className='trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-0 mt-4'>
             <h3 className='headfont '>Fee</h3>
            </div>
            <div className='row m-auto justify-content-center'>
                <div className='col-xl-12 col-lg-12 px-0'>
            <div className='tabtable mt-sm-0 potfolio box darkbox noshadow br-6'>
              
              <div className='themeinput p-sm-4'>
               
              <div className='mb-3 '>
                    <label className='mb-2 text-light'>Deposit Fee %</label>
                    <InputGroup 
                     className="mb-3 password" 
                     id="depositfee">
        <Form.Control
          type="text"
          id="depositFee"
          value={depositFee}
                    
                     
                     onChange={(e) => { setdepositFee(e.target.value) }}
          placeholder="Enter Withdraw Fee"
        />
         
      </InputGroup>
                  </div>

                  
                 <div className='mb-3 '>
                    <label className='mb-2 text-light'>Withdraw Fee %</label>
                    <InputGroup 
                     className="mb-3 password" 
                     id="witfee">
        <Form.Control
          type="text"
          id="withdrawfee"
          value={withdrawFee}
                    
                     
                     onChange={(e) => { setwithdrawFee(e.target.value) }}
          placeholder="Enter Withdraw Fee"
        />
         
      </InputGroup>
                  </div>

                
         
                   
                 
                 <div className='text-end pb-4'>
                  <button className='btn btn-theme mt-4'onClick={() => onSubmit()}>Update Fee</button></div>
                 
              </div>
              </div>
              </div>
            </div>
         </div>
      </div> */}
        
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default Dashboard