import axios from "axios";
import * as config from "../../config/env";
import { sleep } from "./UseGetController";
import { style, iconTheme, position } from '../UseToast'

import toast, { Toaster } from 'react-hot-toast';
// import setAuthToken from "../../store/setAuthToken";

export const UseAdminLogin = (param) => {


    axios
        .post(config.backurl + "/api/admin/login", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {
                sessionStorage.setItem("AToken", res.data.accessToken)
                sessionStorage.setItem("adminusername", res.data.username)

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)

                window.location.href = config.frontendurl + "/dashboard";

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseNewSetting = (param) => {


    axios
        .post(config.backurl + "/api/admin/new/setting/:token", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {


                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)

                window.location.href = config.frontendurl + "/admin";

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseUpdateSetting = (param) => {


    axios
        .post(config.backurl + "/api/admin/update/setting/:token", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {


                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)

                window.location.href = config.frontendurl + "/admin";

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};



export const logoutUser = () => {


    sessionStorage.removeItem("AToken")
    sessionStorage.removeItem("adminusername")


    // setAuthToken(false);
    window.location.href = config.frontendurl + "/login";
};


export const UseisKyc = (param) => {


    axios
        .post(config.backurl + "/api/user/isKyc/verify", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/user";
                // window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseAdminApprove = (param) => {


    axios
        .post(config.backurl + "/api/admin/deposit/approve", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/deposit";
                // window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};

export const UseAdminApproveWithdraw = (param) => {


    axios
        .post(config.backurl + "/api/admin/withdraw/approve", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/withdraw";
                // window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseadminReplymessage = (param) => {


    axios
        .post(config.backurl + "/api/admin/reply/meaage", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {


                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)

                window.location.href = config.frontendurl + "/contact"

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseadminResetpassword = (param) => {


    axios
        .post(config.backurl + "/api/admin/reset/data", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {


                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)

                window.location.href = config.frontendurl + "/contact"

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseUpdateIsExisit = (param) => {
    axios
        .post(config.backurl + "/api/admin/isExisit/user", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/user"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};

export const UseisAuth = (param) => {


    axios
        .post(config.backurl + "/api/user/isauth/:token", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(3000)

                window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};




export const UseUploadAdminBank = (param) => {
    console.log("parammm", param);
    axios
        .post(config.backurl + "/api/admin/createBank", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/adminbank"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};




export const UseAdminBankStatus = (param) => {

    axios
        .post(config.backurl + "/api/admin/bankstatus", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/adminbank"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};

export const UseUpdateBank = (param) => {

    axios
        .post(config.backurl + "/api/admin/updatebank", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/adminbank"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};



export const UseUpdateFeeAmount = (param) => {

    axios
        .post(config.backurl + "/api//admin/updateFee", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/settings"
            } else {
                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};


export const UseUserBalanceUpdate = async (data) => {
    await axios
        .post(config.backurl + "/api/admin/updateUserBalance", data)
        .then(async (res) => {

            if (res.status === 200 && res.data.success === true) {
                toast.success(`${res.data.message}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            } else {
                toast.danger(`${res.data.message}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
            console.log("UseuserBalanceUpdate", err)
        }
        );
}

