import React,{useState,useEffect} from 'react';
import { Button,Dropdown, Tab } from 'react-bootstrap';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';

import coin1 from '../Assets/Images/coin1.png'
import coin2 from '../Assets/Images/coin2.png'
import coin3 from '../Assets/Images/coin3.png'
import coin4 from '../Assets/Images/coin4.png'
import coin5 from '../Assets/Images/coin5.png'
import { getAlluserInfo } from '../Hooks/UseBackendAction/UseGetController';
import { searchedValue } from '../Hooks/UseValidation';


export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};

const Kyc = () =>{
  
  const columns = [
    {
      name: "User ID",
      selector: (vals) => vals.userId,
      sortable: true,
      minWidth: "210px"
    },
    {
      name: "Username",
      selector: (vals) => vals.user_name,
      sortable: true,
      minWidth: "210px"
    },
    {
      name: "Status",
      selector: (vals) => vals.KYCFile.length > 0 ? vals.isKYCVerify === "true" ? "Verified" : "Cancelled" : "No KYC",
      sortable: true
    },
  
   
]  

const [records, setRecords] = useState([])
const [Search, setSearch]   = useState("")
const [isSearch, setisSearch] = useState(false)
const [SearchCoinData, SetSearchCoinData] = useState([])

useEffect(() => {
  getData();

}, [])


const handleChange = async (e) => {
        
  if((e.target.value).length > 0){
        const searchData = await searchedValue(records,e.target.value);
        
       
                                 SetSearchCoinData(searchData)
                                
                                 setisSearch(true)
  }else{
      setisSearch(false)
  }

}
const getData = async () => {
  var data = await getAlluserInfo();
  
  setRecords(data.success ? data.data : [])
};

return(
<>
 <div className='history dashboard transsec'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
            <h3 className='headfont '>KYC List</h3>
            </div>
            
               <div className='tabtable mt-5 potfolio box darkbox'>
               <h4 className='headfont'>KYC List</h4>
               <div className=' themeinput mb-3'>
                    <input type="text"  onChange={(e)=>{setSearch(e.target.value);handleChange(e)}} placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
                </div>
               {!isSearch ?
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={records}/> : 
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={SearchCoinData}/>}
               </div>
            
            
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default Kyc