import React, { useState, useEffect } from 'react';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';
import ReplyContact from './Modals/ReplyContact';
import { getContactinfo } from '../Hooks/UseBackendAction/UseGetController';



export const data = {
  labels: ['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options: {
    cutout: "50%",
    plugins: {
      legend: {
        display: true,
        margin: 10,
        position: "right",
        align: "center",
        labels: {
          usePointStyle: true,
          padding: 20,
          color: "#fff"
        },
      },
    },
  }

};

const Contact = () => {

  const columns = [

    {
      name: "S.No",
      selector: (vals, index) => index + 1,
      sortable: true,

    },
    {
      name: "Username",
      selector: (vals) => vals.name,
      sortable: true
    },
    {
      name: "Email",
      selector: (vals) => vals.email,
      sortable: true,
      minWidth: "270px"
    },
    {
      name: "Phone No",
      selector: (vals) => vals.phoneNo,
      sortable: true,
      minWidth: "170px"
    },
    {
      name: "Message",
      selector: (vals) => vals.comments,
      sortable: true,
      minWidth: "250px"
    },
    {
      name: "Country",
      selector: (vals) => vals.country,
      sortable: true,
      maxWidth: "100px"
    },
    {
      name: "Action",
      selector: (vals) => <button className='btn btn-theme iconbtn' onClick={() => { editRecord(vals); setReply(true) }}><span className='fa fa-reply'></span></button>,
      sortable: true,
      maxWidth: "80px"
    },

  ]
  const vals = [
    {
      col1: "Jhon",
      btn: "ch@yopmail.com",
      msg: "Lorem ipsum is dummy content",
      col3: <button className='btn btn-theme iconbtn' onClick={() => { editRecord(vals); setReply(true) }}><span className='fa fa-reply'></span></button>,
    },

  ]
  const [reply, setReply] = useState(false)
  const [records, setRecords] = useState([])
  const [Currentrecords, setCurrentRecords] = useState({})


  useEffect(() => {
    getData();
  }, [])




  const getData = async () => {
    var data = await getContactinfo();
    setRecords(data.success ? data.data : [])
  };

  const editRecord = async (record) => {
    setCurrentRecords(record)
  }
  return (
    <>
      {reply && <ReplyContact record={Currentrecords} onDismiss={() => setReply(false)} />}

      <div className='history dashboard transsec'>
        <Header />
        <div className='page'>
          <div className='sidebar'><Sidebar /></div>
          <div className='content trans'>
            <div className='container-fluid container-theme p-4'>
              <div className='mb-4'>
                <h3 className='headfont '>Enquiry Lists</h3>
              </div>
              <div className='tabtable mt-5 potfolio box darkbox noshadow contacttabs'>
                <h4 className='headfont'>Enquiry Lists</h4>

                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={records} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact