import React,{useState,useEffect,useRef} from 'react';

import Header from '../Header';
import Sidebar from '../Sidebar';
import toast, { Toaster } from 'react-hot-toast';
import DataTable from 'react-data-table-component';
import DepositModal from '../Modals/DepositModal';
import { CSVLink } from 'react-csv'
import { getAllDepositProof, getAlluserInfo } from '../../Hooks/UseBackendAction/UseGetController';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { searchedValue } from '../../Hooks/UseValidation';
import CopyToClipboard from "react-copy-to-clipboard";
import { style, iconTheme, position } from '../../Hooks/UseToast'


export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};


const copyCode = (a, b) => {
  toast.success("Wallet address Copied", {
      position: position.position,
      style: style,
      iconTheme: iconTheme,
  })
}

const Deposit = () =>{
  
  const columns = [
    {
      name: "Deposit Date",
      selector: (vals) => ((vals.updatedAt).substring(0, 10)),
      sortable: true,
      minWidth: "160px"
    },
    {
      name: "User ID",
      selector: (vals) => vals.userId,
      sortable: true,
      minWidth: "170px"
    },
    {
      name: "Username",
      selector: (vals) => vals.user_name,
      sortable: true,
      minWidth: "170px"

    },
    {
      name: "To Account",
      selector: (vals) => vals.toaccount,
      sortable: true,
      minWidth: "250px"
    },
    {
      name: "Currency",
      selector: (vals) => vals.currency,
      sortable: true,
      minWidth: "100px"
    },
    {
      name: "Deposit Amount",
      selector: (vals) => vals.amount,
      sortable: true
    },
    {
      name: "ZARC Amount",
      selector: (vals) => parseFloat(vals.ZarcAmount).toFixed(5),
      sortable: true
    },
    {
      name: "Admin Fee",
      selector: (vals) => parseFloat(vals.adminFee).toFixed(5),
      sortable: true
    },
    {
      name: "Wallet Address",
      selector: (vals) =>
        <div className='d-flex'>{vals?.walletAddress && vals?.walletAddress.substring(0, 5)}...{vals?.walletAddress && vals?.walletAddress.substring(37, 42)}

          <button className='btn btn-link p-0 ms-2 nofocus'>
            <CopyToClipboard text={`${vals?.walletAddress && vals?.walletAddress}`} onCopy={() => copyCode('invite link', `${vals?.walletAddress && vals?.walletAddress}`)}>
              <span className='fa fa-copy text-secondary'></span></CopyToClipboard></button>
        </div>,
      sortable: true,
      minWidth: "250px"
    },
    {
      name: "Network",
      selector: (vals) => vals.network,
      sortable: true
    },

    {
      name: "Status",
      selector: (vals) => vals.isApproved == null ? "pending" : vals.isApproved == true ? "Approved" : "Cancelled",
      sortable: true,
      minWidth: "120px"
    },
    {
      name: "Admin Hash",
      selector: (vals) =>( parseFloat(vals.adminFee) > 0 && vals.adminHash != "")  ?
       <button  className='btn btn-theme iconbtn'><a target="_blank" href={vals.adminHash}><span className='fa fa-external-link'></span></a></button>
        : 
       <button disabled={true} className='btn btn-theme iconbtn'><span className='fa fa-external-link'></span></button>,
      sortable: true,
      minWidth:"80px",
      maxWidth:"80px"
    },

    {
      name: "Action",
      selector: (vals) => <button className='btn btn-theme iconbtn' onClick={() => { editRecord(vals); setDeposit(true) }}><span className='fa fa-check'></span></button>,
      sortable: true,
      minWidth: "80px",
      maxWidth: "80px"
    },





   
]  
const vals = [
  {
    date:"2022-09-27 17:43",
    buyerid: "62a1bc9",
    sellerid: "0x160cb17CcE20511d2",
    email: "BNB",
    google: "Coin",
    col1:"0.2",
    btn: "cancelled",
    col3:<button className='btn btn-theme iconbtn' onClick={() => setDeposit(true)}><span className='fa fa-check'></span></button>,
  },

]
const[deposit, setDeposit] = useState(false)
const [records, setRecords] = useState([])
const [Currentrecords, setCurrentRecords] = useState({})
const csvLink = useRef()
const xlsLink = useRef()
const [Search, setSearch]   = useState("")
  const [isSearch, setisSearch] = useState(false)
  const [SearchCoinData, SetSearchCoinData]      = useState([])

useEffect(() => {
  getData();

}, [])
const getData = async () => {
 
   var data = await getAllDepositProof();
   
   setRecords(data.success ? data.data : [])
};


const handleChange = async (e) => {
        
  if((e.target.value).length > 0){
        const searchData = await searchedValue(records,e.target.value);
        
       
                                 SetSearchCoinData(searchData)
                                
                                 setisSearch(true)
  }else{
      setisSearch(false)
  }

}
const editRecord = async (record) => {
  setCurrentRecords(record)
}

const getTransactionData = async () => {

  csvLink.current.link.click()
}


const DownloadXLS = async () => {

  xlsLink.current.link.click()
}


const exportPDF = async() => {
 
  const result = await getAllDepositProof()
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(13);

  const title = "Trade History";
  const headers = [
    [
      "Username",
      "UserID",
      "Status",
      "Accountnumber",
      "amount",
      "currency",
      "Date",
      "ZarcAmount",
      "Admin Fee"
      
    ],
  ];

  const data =
  
    result && result.data && result.data.length > 0 &&
    result.data.map((elt) => [
      elt.user_name,
      elt.userId,
      elt.isApproved == null ? "pending" : elt.isApproved == true ? "Approved" : "Cancelled",
      elt.toaccount,
      elt.amount,
      elt.currency,
      elt.updatedAt,
      elt.ZarcAmount,
      elt.adminFee

      
    ]);

  let content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save("Deposit.pdf");
}
return(
<>
{deposit && <DepositModal record={Currentrecords} onDismiss={() => setDeposit(false)} />}
 <div className='history dashboard transsec'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
            <h3 className='headfont '>Fiat Deposit List</h3>
            </div>
            <div className='tabtable mt-5 potfolio box darkbox noshadow deposittabs'>
                <h4 className='headfont'>Fiat Deposit List</h4>
                <div className='mb-4'>
                {records && records.length > 0 ?
                  <button onClick={exportPDF} className='btn btn-theme mt-3 me-2'>Download(PDF)</button> : <></>}
                {records && records.length > 0 ? (
                  <>
                    <button onClick={DownloadXLS} className='btn btn-theme mt-3 me-2'>Download(XLS)</button>
                    <CSVLink
                      data={records}
                      filename='Deposit.xls'
                      className='hidden'
                      ref={xlsLink}
                      target='_blank'
                    /> </>) : (
                  ""
                )}

{records && records.length > 0 ? (
                      <>
                              <button  onClick={getTransactionData} className='btn btn-theme mt-3 me-2'>Download(CSV)</button>
                    <CSVLink
         data={records}
         filename='Deposit.csv'
         className='hidden'
         ref={csvLink}
         target='_blank'
      />
      </>) : (
        ""
      )}
          
                </div>
                <div className='themeinput mb-3'>
                    <input type="text"  onChange={(e)=>{setSearch(e.target.value);handleChange(e)}} placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
                </div>
                {!isSearch ?
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={records}/> : 
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={SearchCoinData}/>}
            </div>
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default Deposit