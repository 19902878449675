import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Dashboard from './Components/Dashboard.js'
import Notification from './Components/Notification';
import Currency from './Components/Currency';
import Kyc from './Components/Kyc';
import PairManagement from './Components/PairManagement';
import User from './Components/User';
import OrderHistory from './Components/History/OrderHistory';
import TradeHistory from './Components/History/TradeHistory';
import Withdraw from './Components/History/Withdraw';
import Deposit from './Components/History/Deposit';
import Cms from './Components/Cms';
import Contact from './Components/Contact';
import FaqCategory from './Components/FaqCategory';
import FaqList from './Components/FaqList';
import ChangePwd from './Components/ChangePwd';
import PassBookHistory from './Components/PassBookHistory';
import toast, { Toaster } from 'react-hot-toast';
import AdminLogin from './Components/AminLogin';
import AdminBank from './Components/AdminBank';
import Settings from './Components/settings';

function App() {
  return (
    <div className="App">
       <div><Toaster /></div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard"  element= {<Dashboard />} />
          
        
          <Route path="/currency" element={<Currency />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/settings" element={<Settings />} />
          
          <Route path="/pairmanagement" element={<PairManagement />} />
          <Route path="/user" element={<User />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/orderhistory" element={<OrderHistory />} />
          <Route path="/tradehistory" element={<TradeHistory />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/cms" element={<Cms/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/faqcategory" element={<FaqCategory/>} />
          <Route path="/assetlist" element={<FaqList/>} />
          <Route path="/changepassword" element={<ChangePwd />} />
          <Route path="/passbook" element={<PassBookHistory />} />
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/adminbank" element={<AdminBank />} />

         
 
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
