import React, { useEffect, useState } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'
import { getAllAsset, updateAsset } from '../../Hooks/UseBackendAction/UseGetController';
import toast, { Toaster } from 'react-hot-toast';
import {  style,iconTheme,position } from '../../Hooks/UseToast';
import { ValidateUpdateToken } from '../../Hooks/UseValidation';
import { backurl } from '../../config/env';
const initialFormValue  = {
    'tokenAddress' : '',
     'symbol':'',
     'name':'',
'image':'',
'type':'',
'displayname':''
}
const FaqListModal = (props) =>{
  

//state
   const[faqlist, setFaqList] = useState(true)
   const[updatetoken, setUpdateToken]=useState(initialFormValue)
   const[errors, setError] = useState({})
   
   const{ tokenAddress, symbol, name, image, type,displayname}= updatetoken;

//function

const handleChange = async(e)=>
{
  const name = e.target.name;
  const value = e.target.value;
  setUpdateToken({...updatetoken,[name]:value})
}





const handleselect = async (e)=>
{
  const value = updatetoken?.type;
  console.log('valuie',value)
 setUpdateToken({...updatetoken,...{'type':value}})
 
}

const Ondisplayname = async(e) =>{
  const value = updatetoken?.displayname;
  
 setUpdateToken({...updatetoken,...{'displayname':e.target.value}})
}


const onInputchange = async(e)=>
{
  setUpdateToken({...updatetoken,...{'image':e.target.files[0]}})
}
 


const handleSubmit = async()=>
{
  const reqData = {
    tokenAddress, symbol, name, image, type,displayname
  }
  const {errors,isValid} = await ValidateUpdateToken(reqData)

  if(!isValid)
  {
    setError(errors)
  }
  else
{ 
const formdata = new FormData();
formdata.append('_id',props.data._id)
formdata.append('type',reqData.type)
formdata.append('tokenAddress',reqData.tokenAddress);
formdata.append('symbol',props.data.symbol);
formdata.append('name',reqData.name);
formdata.append('image',reqData.image)
formdata.append('displayname',reqData.displayname)
setError(errors)
 const data = await updateAsset(formdata);

 try{
    if(data?.data?.success == true)
    {
      
     toast.success("Token Update successfully", {
      position:position.position,
       style:style,
       iconTheme: iconTheme,
        })
        props.record()
        props.onDismiss()
    }
    else
    {
      toast.error("Something went wrong", {
        position:position.position,
         style:style,
         iconTheme: iconTheme,
          })
    }
    
 }
 catch(err)
 {

 }
}
}


useEffect(()=>
{

  console.log("props.data",props.data.symbol);
    setUpdateToken(
        {
            'tokenAddress' : props.data.tokenAddress,
            
            'name':props.data.name,
            'symbol' : props.data.symbol,
            'image':props.data.logoURI,
            'type':props.data.type,
            'displayname' : props.data.displayname
        }
    )
},[props.data.tokenAddress])

   return(
    <>
    <Modal size="md"   show={faqlist} onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>

      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll'>
       
      <div className='mb-4 themeinput'>
        <h5 className='headfont mb-4'>Update Asset</h5>

     {  props?.data?.type == 'token'&& 
      <div className='w-full mb-3 '>
                <p className='mb-2'>Type</p>
                <div className='themeinput'>
                <select className='form-select' name='type' onChange={(e)=>{handleselect(e);}}>
                        {/* <option name='type' value={updatetoken.type} >fiat</option> */}
                        <option name='type' value={updatetoken.type} >crypto</option>
                        <option name='type' value={updatetoken.type} >Token</option>
                    </select>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.type}</span>
          </div>
          }
         { props?.data?.type == 'token' && <div className='w-full mb-3'>
                <p className='mb-2' >Address</p>
                <div className='themeinput'>
                <input type='text' name='tokenAddress' className='form-control' value={updatetoken?.tokenAddress} onChange={(e)=>handleChange(e)}></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.tokenAddress}</span>
            </div>}
      
         
          <div className='w-full mb-3'>
                <p className='mb-2' >Symbol</p>
                <div className='themeinput'>
                <input type='text' name='symbol' className='form-control' value={updatetoken?.displayname}  onChange={(e)=>Ondisplayname(e)} ></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.displayname}</span>
            </div>
            <div className='w-full mb-3'>
                <p className='mb-2' >Name</p>
                <div className='themeinput'>
                <input type='text' className='form-control' name='name' value={updatetoken?.name} onChange={(e)=>handleChange(e)}></input>
             </div>
             <span className="text-danger f-12 d-block text-left">{errors.name}</span>
                <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Image</p>
                
                <div className='themefile position-relative editfile'>
               
                 
               { updatetoken.image?.name  ? <><img src={window.URL.createObjectURL(updatetoken?.image)} className="mb-0"/></> : updatetoken?.image ? <><img src={backurl+`/Images/${updatetoken?.image}`}  className="mb-0"/> </> : <></> } 
                  
               <input type='file' className=' mt-2' name='image' onChange={(e)=>onInputchange(e)} />
               <span className='fa fa-edit'></span>
                </div>

                <span className="text-danger f-12 d-block text-left">{errors.image}</span>
            </div>
   </div>
   <div className='text-end'>
       <button className='btn btn-theme mt-4 ' onClick={()=>handleSubmit()}>Update</button>
</div>




   
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default FaqListModal