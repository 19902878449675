import React, { useState } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'

import prof1 from '../../Assets/Images/prof1.jpg'




const AddSpot = (props) =>{
    
   const[addspot, setAddSpot] = useState(true)

   return(
    <>
    <Modal size="lg" show={addspot}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='px-lg-5 themescroll'>
       
      <div className='my-4 '>
  

      <div className=' noshadow br-6'>
              <h4 className='headfont mb-4 px-4'>Add Spot</h4>
              
              <div className='themeinput p-sm-4 p-3'>
                <div className='d-lg-flex gap-3'>
                     <div className='mb-3 w-full'>
                    <label className='mb-2 text-light'>Base Currency</label>
                    <select className='form-select' >
                        <option>BTC</option>
                        <option>BNB</option>
                    </select>

                 </div>
                 <div className='mb-3 w-full'>
                    <label className='mb-2 text-light'>Base Currency Decimal</label>
                    <input type="text" className='form-control' />
                 </div>
                </div>
                
                <div className='d-lg-flex gap-3'>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Quote Currency</label>
                        <select className='form-select' >
                        <option>BTC</option>
                        <option>BNB</option>
                    </select>
                    </div>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Quote Currency Decimal</label>
                        <input type="text" className='form-control' />
                    </div>
                 </div>
                 <div className='d-lg-flex gap-3'>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Maker Fee(%)</label>
                        <input type="text" className='form-control' placeholder='0.1'/>
                    </div>
                    <div className='mb-3 w-full'>
                        <label className='mb-2 text-light'>Taker Fee(%)</label>
                        <input type="text" className='form-control' placeholder='0.1'/>
                    </div>
                 </div>
                 <div className='d-lg-flex gap-3'>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Minimum Price(%)</label>
                        <input type="text" className='form-control' placeholder='1000'/>
                    </div>
                    <div className='mb-3 w-full'>
                        <label className='mb-2 text-light'>Maximum Price(%)</label>
                        <input type="text" className='form-control' placeholder='1000' />
                    </div>
                 </div>

                 <div className='d-lg-flex gap-3'>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Minimum Quantity</label>
                        <input type="number" className='form-control' placeholder='0.000001'/>
                    </div>
                    <div className='mb-3 w-full'>
                        <label className='mb-2 text-light'>Maximum Quantity</label>
                        <input type="text" className='form-control' placeholder='100'/>
                    </div>
                 </div>

                 <div className='d-lg-flex gap-3'>
                    
                    <div className='mb-3 w-full'>
                        <label className='mb-2 text-light'>Markprice</label>
                        <input type="text" className='form-control' placeholder='800'/>
                    </div>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Binance Integration</label>
                        <select className='form-select' >
                        <option>Binance</option>
                        <option>Spot status</option>
                        <option>Off</option>
                    </select>
                    </div>
                 </div>

              <div className='mt-5'><button className='btn btn-theme '>Add </button></div> 
              </div>
           

</div>



   
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default AddSpot