import React, { useState } from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import prof from '../../Assets/Images/preference.svg'

const Preference = (props) =>{
    const[preference, setPreference ]= useState(true)


   return(
    <>
    <Modal show={preference} size="sm" centered scrollable className='modal_style thememodal preference'>
      <Modal.Header className='border-0 pb-0'>
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='pt-0'>
      <div className='mb-4'>
        <div className=''>
          <img src={prof} />
            <div className='mb-3'>
                <h5 className='mb-0'>Preference</h5>
                <p className='text-muted f-14 pb-0 mb-0'>Lorem ipsum is simply a dummy text</p>
            </div>
        </div>
        <div className='mt-4 pe-5 text-start'>
           <div className='themeinput'>
                    <label>Time zone</label>
                    <input placeholder='(GMT +2:00) pretoria' className='form-control'/>
            </div>
            <div className='themeinput mt-3'>
                    <label>Logout Timeout</label>
                    <input placeholder='5min (Default)' className='form-control'/>
            </div>
            
        </div>
        
        <div className='text-end mt-4'>
            <button className='btn btn-theme'>Update</button>
            </div>
        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default Preference