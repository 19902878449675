import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'

import DataTable from 'react-data-table-component';

import coin1 from '../../Assets/Images/coin1.png'
import coin2 from '../../Assets/Images/coin2.png'
import coin3 from '../../Assets/Images/coin3.png'
import coin4 from '../../Assets/Images/coin4.png'
import coin5 from '../../Assets/Images/coin5.png'
import { UseUserBalanceUpdate } from '../../Hooks/UseBackendAction/UseAdmin';



const UserDetails = (props) => {
  const [userdeatils, setUserDeatils] = useState(true)
  const [showtoken, setShowToken] = useState(false)
  const [selected, setSelected] = useState("")
  const [records, setRecords] = useState([])
  const [amount, setAmount] = useState([])
  useEffect(() => {
    
    setRecords(props?.record?.Holding)
  }, [])

  const columns = [
   
    {
      name: "Token Name",
      selector: (vals) => vals.symbol === "ZARC" ? "ZARC" : vals.symbol === "ZARCSol" ? "ZARC" : vals.symbol === "ZARCEth" ? "ZARC" : vals.symbol,
      sortable: true
    },
    {
      name: "Network",
      selector: (vals) => vals.chainId === "56" ? "BSC" : vals.chainId === "1" ? "ETH" : "SOLANA",
      sortable: true
    },
    {
      name: "Balance",
      selector: (vals, index) => (
        <div className='btn_input_new'>
          <input className="form-control" value={amount.length > 0 ? amount[index] : vals.amount} onChange={(e) => { onchangeAmount(e.target.value, index) }} />
          <button className="btn btn btn-theme" onClick={()=> {updateUserBalance(vals,index)}}>Update</button>
        </div>
        ),
      sortable: true
    },
    // {
    //   name: "Derivative Balance",
    //   selector: (vals) => vals.count,
    //   sortable: true
    // },
    // {
    //   name: "P2P Balance",
    //   selector: (vals) => vals.time,
    //   sortable: true
    // }


  ]

  const onchangeAmount = (data, index) => {
    let instarr = amount;
    instarr[index] = data
    console.log('onchangeAmount', data, index, instarr);
    setAmount([...instarr])
  }
  
  const updateUserBalance = async (data, index) => {
    let payload = {
      amount: amount[index] == undefined ? parseFloat(data.amount) : parseFloat(amount[index]),
      token: data.symbol,
      userAddress: props?.record?.userId,
      chainId: data.chainId
    }
    const updateBalance = await UseUserBalanceUpdate(payload);
  }

  return (
    <>
      <Modal size="xl" show={userdeatils} onHide={() => props.onDismiss()} centered scrollable className='modal_style thememodal'>
{console.log('amountamount',amount)}
        <Modal.Header className='border-0 pb-0'>

          <button type="button" className="btn close close_modal ms-auto text-white" onClick={() => { props.onDismiss() }}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </Modal.Header>
        <Modal.Body className='p-lg-5 themescroll'>
          <div className='mb-4 '>
            <div className='d-sm-flex mt-3 jc-between align-items-center'>

              <h5 className='headfont'>User Asset Details</h5>
              {/* <div className='themeinput mb-3'>
           <input type="text" placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
       </div>  */}
            </div>


            <div className='themeinput themeinput_table tabtable  potfolio mt-4 '>



              <DataTable pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={records} />
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default UserDetails