import React, { useState, useEffect } from 'react';
import { Button, Tabs, Tab, Form, Dropdown, InputGroup } from 'react-bootstrap';

import Header from './Header';
import Sidebar from './Sidebar';
import { getAllFeeInfo } from '../Hooks/UseBackendAction/UseGetController';
import { UseUpdateFeeAmount } from '../Hooks/UseBackendAction/UseAdmin';






const Settings = () => {

    const [id, setid] = useState("")
    const [depositFee, setdepositFee] = useState("")
    const [withdrawFee, setwithdrawFee] = useState("")
    const [adminwalletBSC, setadminwalletBSC] = useState("")
    const [adminwalletSOL, setadminwalletSOL] = useState("")
    const [adminwalletETH, setadminwalletETH] = useState("")
    const [privatekeyBSC, setprivatekeyBSC] = useState("")
    const [privatekeySOL, setprivatekeySOL] = useState("")
    const [privatekeyETH, setprivatekeyETH] = useState("")




    useEffect(() => {
        getData();




    }, [])
    const getData = async () => {


        var feedata = await getAllFeeInfo();

        setid(feedata?.data[0]?._id);
        setdepositFee(feedata?.data[0]?.depositFee);
        setwithdrawFee(feedata?.data[0]?.withdrawFee);
        setadminwalletBSC(feedata?.data[0]?.adminwalletBSC)
        setadminwalletSOL(feedata?.data[0]?.adminwalletSOL)
        setadminwalletETH(feedata?.data[0]?.adminwalletETH)
        setprivatekeyBSC(feedata?.data[0]?.privatekeyBSC)
        setprivatekeySOL(feedata?.data[0]?.privatekeySOL)
        setprivatekeyETH(feedata?.data[0]?.privatekeyETH)

    };
    const onSubmit = async () => {

        const data = {

            id: id,
            depositFee: depositFee,
            withdrawFee: withdrawFee,
            adminwalletBSC: adminwalletBSC,
            adminwalletSOL: adminwalletSOL,
            adminwalletETH: adminwalletETH,
            privatekeyBSC: privatekeyBSC,
            privatekeySOL: privatekeySOL,
            privatekeyETH: privatekeyETH,

        };

        await UseUpdateFeeAmount(data)

    };
    return (
        <>
            <div className='history dashboard transsec'>
                <Header />
                <div className='page'>
                    <div className='sidebar'><Sidebar /></div>
                    <div className='content trans'>
                        <div className='container-fluid container-theme p-4'>
                            <div className='mb-4'>
                                <h3 className='headfont '>Settings</h3>
                            </div>
                            <div className='row m-auto justify-content-center'>
                                <div className='col-xl-12 col-lg-12 px-0'>
                                    <div className='tabtable mt-sm-0 potfolio box darkbox noshadow br-6'>

                                        <div className='themeinput p-sm-4'>
                                            <div className='mb-3 '>
                                                <label className='mb-2 text-light'>Deposit Fee %</label>
                                                <InputGroup
                                                    className="mb-3 password"
                                                    id="depositfee">
                                                    <Form.Control
                                                        type="text"
                                                        id="depositFee"
                                                        value={depositFee}


                                                        onChange={(e) => { setdepositFee(e.target.value) }}
                                                        placeholder="Enter Withdraw Fee"
                                                    />

                                                </InputGroup>
                                            </div>


                                            <div className='mb-3 '>
                                                <label className='mb-2 text-light'>Withdraw Fee %</label>
                                                <InputGroup
                                                    className="mb-3 password"
                                                    id="witfee">
                                                    <Form.Control
                                                        type="text"
                                                        id="withdrawfee"
                                                        value={withdrawFee}


                                                        onChange={(e) => { setwithdrawFee(e.target.value) }}
                                                        placeholder="Enter Withdraw Fee"
                                                    />

                                                </InputGroup>
                                            </div>




                                            <div className='mb-3 '>
                                                <label className='mb-2 text-light'>Admin Fee Collector Address(BSC)</label>
                                                <InputGroup
                                                    className="mb-3 password"
                                                    id="witfee">
                                                    <Form.Control
                                                        type="text"
                                                        id="withdrawfee"
                                                        value={adminwalletBSC}


                                                        onChange={(e) => { setadminwalletBSC(e.target.value) }}
                                                        placeholder="Enter Admin Wallet address in BSC"
                                                    />

                                                </InputGroup>
                                            </div>
                                            <div className='mb-3 '>
                                                <label className='mb-2 text-light'>Admin Fee Collector Address(SOL)</label>
                                                <InputGroup
                                                    className="mb-3 password"
                                                    id="witfee">
                                                    <Form.Control
                                                        type="text"

                                                        value={adminwalletSOL}


                                                        onChange={(e) => { setadminwalletSOL(e.target.value) }}
                                                        placeholder="Enter Admin Wallet address in SOL"
                                                    />

                                                </InputGroup>
                                            </div>

                                            <div className='mb-3 '>
                                                <label className='mb-2 text-light'>Admin Fee Collector Address(ETH)</label>
                                                <InputGroup
                                                    className="mb-3 password"
                                                    id="witfee">
                                                    <Form.Control
                                                        type="text"

                                                        value={adminwalletETH}


                                                        onChange={(e) => { setadminwalletETH(e.target.value) }}
                                                        placeholder="Enter Admin Wallet address in ETH"
                                                    />

                                                </InputGroup>
                                            </div>

                                            <div className='mb-3 '>
                                                <label className='mb-2 text-light'>Private key(BSC)</label>
                                                <InputGroup
                                                    className="mb-3 password"
                                                    id="witfee">
                                                    <Form.Control
                                                        type="text"

                                                        value={privatekeyBSC}


                                                        onChange={(e) => { setprivatekeyBSC(e.target.value) }}
                                                        placeholder="Enter private key in BSC"
                                                    />

                                                </InputGroup>
                                            </div>
                                            <div className='mb-3 '>
                                                <label className='mb-2 text-light'>Private key(SOL)</label>
                                                <InputGroup
                                                    className="mb-3 password"
                                                    id="witfee">
                                                    <Form.Control
                                                        type="text"

                                                        value={privatekeySOL}


                                                        onChange={(e) => { setprivatekeySOL(e.target.value) }}
                                                        placeholder="Enter private key in SOL"
                                                    />

                                                </InputGroup>
                                            </div>
                                            <div className='mb-3 '>
                                                <label className='mb-2 text-light'>Private key(ETH)</label>
                                                <InputGroup
                                                    className="mb-3 password"
                                                    id="witfee">
                                                    <Form.Control
                                                        type="text"

                                                        value={privatekeyETH}


                                                        onChange={(e) => { setprivatekeyETH(e.target.value) }}
                                                        placeholder="Enter private key in ETH"
                                                    />

                                                </InputGroup>
                                            </div>


                                            <div className='text-end pb-4'>
                                                <button className='btn btn-theme mt-4' onClick={() => onSubmit()}>Update</button></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings