import {backurl} from '../../config/env';
import axios from "axios";
import * as config from "../../config/env";
export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const getAlluserInfo = async() => {
    
   const userinfo =  await   axios.get(`${backurl}/api/user/all/info`)
         return userinfo.data;
}


export const getAllDepositProof = async() => {
    
      const userinfo =  await   axios.get(`${backurl}/api/user/deposit/proof`)
            return userinfo.data;
   }


   export const getAllWithdrawrequest = async() => {
    
      const userinfo =  await   axios.get(`${backurl}/api/user/withdraw/request`)
            return userinfo.data;
   }


   export const getAllTransaction = async() => {
    
      const userinfo =  await   axios.get(`${backurl}/api/user/all/transaction`)
            return userinfo.data;
   }
   
   export const getContactinfo = async() => {
     
    
      const Contactinfo =  await   axios.get(`${backurl}/api/contact/info`)
            return Contactinfo.data;
   }
 
   export const getWithdrawisApproved = async() => {
     
    const Contactinfo =  await   axios.get(`${backurl}/api/admin/withdraw/approved`)
            return Contactinfo.data;
   }


   export const getDepositisApproved = async() => {
     
      const Contactinfo =  await   axios.get(`${backurl}/api/admin/deposit/approved`)
              return Contactinfo.data;
     }
 
     export const getKYCisApproved = async() => {
     
      const Contactinfo =  await   axios.get(`${backurl}/api/admin/kycdata`)
              return Contactinfo.data;
     }
 
     export const getKYCisAllApproved = async() => {
     
      const Contactinfo =  await   axios.get(`${backurl}/api/admin/allkycdata`)
              return Contactinfo.data;
     }
     
     export const getAllAsset = async ()=>
     {
      const Assetinfo = await  axios.get(`${backurl}/api/admin/allassetdata`)
      return Assetinfo.data;
     }
     
     export const Addasset = async(data)=>
     {
      const Assetinfo = await axios.post(config.backurl + '/api/admin/addasset',data)
       return Assetinfo.data;
     }

     export const updateAsset = async(data)=>
     {
      const Assetinfo = await  axios.post(config.backurl +'/api/admin/updateasset',data)
      return Assetinfo;
     }

     export const updateEnabled = async(data)=>
     {
      console.log('datassssss',data)
      const Assetinfo = await  axios.post(config.backurl +'/api/admin/updateassetEnabled',data)
      return Assetinfo;
     }


     export const getAllBankInfo = async ()=>
     {
      const Bankinfo = await  axios.get(`${backurl}/api/getadminbank`);
      console.log("Bankinfo",Bankinfo);
      return Bankinfo.data;
     }
     

//      export const getAllFeeInfo = async ()=>
//      {
//       const Bankinfo = await  axios.get(`${backurl}/api/admin/getFee`);
     
//       return Bankinfo.data;
//      }
     

     export const getAllFeeInfo = async ()=>
{

   const token = sessionStorage.getItem('AToken');
 const Bankinfo = await  axios.get(`${backurl}/api/admin/getFee`,{headers: {authorization : `Bearer ${token}`}});

 return Bankinfo.data;
}


     export const getSolanaBalance = async ()=>
     {

      try{

            const Bankinfo = await  axios.get(`${backurl}/api/solana/info`);
           
             return Bankinfo?.data?.data;
      }catch(e){
            
      }
      
     }


     export const getSolanaBalanceToken = async ()=>
     {

      try{
            const Bankinfo = await  axios.get(`${backurl}/api/solanatoken/info`);
           
     
            return Bankinfo?.data?.data;
      }catch(e){
            
      }
   
     }
     
