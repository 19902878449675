import React, { useState } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'

import prof1 from '../../Assets/Images/prof1.jpg'




const UpdateCurrency = (props) =>{
    
   const[updatecurrency, setUpdateCurrency] = useState(true)

   return(
    <>
    <Modal size="lg" show={updatecurrency}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='px-lg-5 themescroll'>
       
      <div className='my-4 '>
  

    <div className=' noshadow br-6'>
              <h4 className='headfont mb-4 px-4'>Update Currency</h4>
              
              <div className='themeinput p-sm-4 p-3'>
                <div className='d-lg-flex gap-3'>
                     <div className='mb-3 w-full'>
                    <label className='mb-2 text-light'>Currency Type</label>
                    <select className='form-select' >
                        <option>Crypto</option>
                        <option>Fiat</option>
                        <option>Crypto</option>
                    </select>

                 </div>
                 <div className='mb-3 w-full'>
                    <label className='mb-2 text-light'>Currency Type</label>
                    <input type="text" className='form-control' />
                 </div>
                </div>
                
                <div className='d-lg-flex gap-3'>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Coin</label>
                        <input type="text" className='form-control' />
                    </div>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Symbol</label>
                        <input type="text" className='form-control' />
                    </div>
                 </div>

                 <div className='d-lg-flex gap-3'>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Decimal</label>
                        <input type="number" className='form-control' />
                    </div>
                    <div className='mb-3  w-full '>
                        <label className='mb-2 text-light'>Deposit Status</label>
                        <select className='form-select' >
                        <option>On</option>
                        <option>Off</option>
                    </select>
                    </div>
                 </div>

                 <div className='d-lg-flex gap-3'>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Withdraw Status</label>
                        <select className='form-select' >
                        <option>On</option>
                        <option>Off</option>
                    </select>
                    </div>
                    <div className='mb-3 w-full'>
                        <label className='mb-2 text-light'>Withdrawal Fee(%)</label>
                        <input type="text" className='form-control' />
                    </div>
                 </div>

                 <div className='d-lg-flex gap-3'>
                    <div className='mb-3 w-full '>
                        <label className='mb-2 text-light'>Minimum Withdrawal</label>
                        <input type="text" className='form-control' />
                    </div>
                    <div className='mb-3  w-full'>
                        <label className='mb-2 text-light'>Deposit Type</label>
                        <select className='form-select' >
                        <option>Local</option>
                        <option>Binance</option>
                        <option>Copy payment</option>
                    </select>
                    </div>
                 </div>
                 <div className='d-lg-flex gap-3'>
                    <div className='mb-3 w-full '>
                    <label className='mb-2 text-light'>Minimum Deposit</label>
                    <input type="text" className='form-control' />
                 </div>
                 <div className='mb-3 w-full'>
                    <label className='mb-2 text-light'>Status</label>
                    <select className='form-select' >
                        <option>Active</option>
                        <option>Inactive</option>
                    </select>

                 </div>
                 </div>

                 <div className='mb-3 '>
                    <label className='mb-2 text-light'>Currency icon</label>
                    <div className='currencyicon d-sm-flex gap-4'>
                     <div> <button className='btn btn-theme choosebtn'>Choose File<input type="file" /></button></div>
                      <img src={prof1} className="mt-3 mt-sm-0"/>
                    </div>
                   
                 </div>
              <div className='mt-5'><button className='btn btn-theme '>Update</button></div> 
              </div>
           

</div>



   
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default UpdateCurrency