import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import bcoin from '../../Assets/Images/bcoin1.png'
import { backurl } from '../../config/env';
import { UseAdminApprove } from '../../Hooks/UseBackendAction/UseAdmin';

const DepositModal = (props) => {

    const [deposit, setDeposit] = useState(true)
    // const [transactionID, settransactionID] = useState(props.record.transactionID)
    const [amount, setamount] = useState(props.record.amount)
    const [id, setid] = useState(props.record._id)
    const [Image, setImage] = useState(props.record.logoURI)
    const [status, setStatus] = useState(props.record.isApproved)
    const [currency, setCurrency] = useState(props.record.currency)
    const [ZarcAmount, setZarcAmount] = useState(props.record.ZarcAmount)
    const [TowalletAddress, setTowalletAddress] = useState(props.record.walletAddress)
    const [adminFee, setadminFee] = useState(props.record.adminFee)
    const[btn_status,setbtn_status] = useState("Approve")
    const[declinedStatus,setDeclinedStatus] = useState(false)
    const [declineValue,setDeclineValue] = useState("");
    const [err,setErr] = useState("");

    useEffect(() => {
        console.log("props>>>>", props.record);

        // if(props.record.isApproved){
        //     setbtn_status("Approved") 
        // }else if(props.record.isApproved == false){
        //     setbtn_status("Cancelled") 
        // }else if(props.record.isApproved == null){
        //     setbtn_status("Pending") 
        // }
    })


    const onAdminApprove = async (value) => {

        const isKycdata = {
            user_name: props.record.user_name,
            Currency: props.record.currency,
            _id: id,
            amount: parseFloat(ZarcAmount),
            isApproved: value,
            HoldingData: props.record,
            depositTime: props.record.updatedAt.substring(0, 10),
            TowalletAddress: TowalletAddress,
            network: props?.record?.network,
            adminFee: adminFee,
            date: new Date(Date.now()).toDateString(),
            reason: declineValue
        };
        if(declineValue == "" && declinedStatus == true){
            setErr("* Reason is required")
        }else{
            await UseAdminApprove(isKycdata);
            setbtn_status("Approving...")
        }
    };

    const fileDownloader = (URL) => {
        fetch(URL).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = url;
                a.click();
            });
        })
    }

    return (
        <>
            <Modal size="md" show={deposit} onHide={() => props.onDismiss()} centered scrollable className='modal_style thememodal'>

                <Modal.Header className='border-0 pb-0'>

                    <button type="button" className="btn close close_modal ms-auto text-white" onClick={() => { props.onDismiss() }}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body className='p-lg-5 themescroll'>

                    <div className='mb-4 '>
                        <h5 className='headfont mb-4'>Details</h5>
                        <div className='details'>
                            {/* <div className='d-flex flex-1'>
                                <p className=' text-muted'>Transaction ID</p>
                                <p className='trunkon200'> {transactionID}</p>
                            </div> */}
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Deposit Amount</p>
                                <p>{amount} {currency}</p>
                            </div>
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>ZARC Amount</p>
                                <p>{ZarcAmount} </p>
                            </div>
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Admin Fee</p>
                                <p className="word_break_text">{parseFloat(adminFee) > 0 ? adminFee : "0"} </p>
                            </div>
                            {/* <div className='d-flex flex-1'>
                    <p className=' text-muted'>Deposited Amount</p>
                    <p>45</p>
                </div> */}
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Document</p>
                                <div className='documrnt_flex'>
                                <a target="_blank" className='doc_link' href={backurl + `/Images/${Image}`}>View Document</a>
                                <Button className='btn btn-theme btn_upload_admin' onClick={() => { fileDownloader(backurl + `/Images/${Image}`) }}>
                                    <i className='fa fa-download' />
                                </Button>
                                </div>
                                {/* <img src={backurl + `/Images/${Image}`} className="docimg" /> */}
                            </div>
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Status</p>
                                <p>{status == null ? "Pending" : status == true ? "Approved" : "Cancelled"}</p>
                            </div>

                            {  !declinedStatus && <div className='text-end mt-4 w-full'>
                                <button className='btn btn-theme me-2' onClick={() => { onAdminApprove(true) }}>{btn_status}</button>
                                <button className='btn btn-theme' onClick={() => { setDeclinedStatus(true) }}>Decline</button>
                            </div>}
                            {
                                declinedStatus &&
                                <div className='text-end mt-4 w-full themeinput'>
                                    {/* <input className='form-control mb-3' placeHolder="Reason for decline" value={declineValue} onChange={(e) => { setDeclineValue(e.target.value) }} /> */}
                                    <textarea rows="4" cols="50" className='depositmodal_textarea form-control mb-3' placeHolder="Reason for decline" value={declineValue} onChange={(e) => { setDeclineValue(e.target.value) }}/>
                                    <p className="text-danger text-start">{err}</p>
                                    <button className='btn btn-theme me-2' onClick={() => { onAdminApprove(false) }}>Decline</button>
                                    <button className='btn btn-theme' onClick={() => { setDeclinedStatus(false) }}>cancel</button>
                                </div>
                            }
                           
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </>
    )
}

export default DepositModal