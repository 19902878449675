import React,{useState} from 'react';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';
import FaqCategoryModal from './Modals/FaqCategoryModal';
import AddFaqCat from './Modals/AddFaqCat';



export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};

const FaqCategory = () =>{
  
  const columns = [


    {
        name: "Category Name",
        selector: (vals) => vals.col1,
        sortable: true,
    },
    {
      name: "Status",
      selector: (vals) => vals.btn,
      sortable: true
    },
   
    {
        name: "Action",
        selector: (vals) => vals.col3,
        sortable: true,
        maxWidth: "80px"
    },
 
     
     
   
      
   
]  
const vals = [
  {
   
    col1:"Test",
    btn: "Deactive",
    col3:<button className='btn btn-theme iconbtn' onClick={() => setFaqCategory(true)}><span className='fa fa-edit'></span></button>,
  },

]
const[faqcategory, setFaqCategory] = useState(false)
const[addfaqcat, setAddFaqcat] = useState(false)

return(
<>
{faqcategory && <FaqCategoryModal onDismiss={() => setFaqCategory(false)} />}
{addfaqcat && <AddFaqCat onDismiss={() => setAddFaqcat(false)} />}
 <div className='history dashboard transsec'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
             <h3 className='headfont '>FAQ Category</h3>
            </div>
            <div className='tabtable mt-5 potfolio box darkbox noshadow'>
              <div className='d-flex jc-between'>  <h4 className='headfont'>FAQ Category</h4>
              <button className='btn btn-theme' onClick={() => setAddFaqcat(true)}><span className='fa fa-plus me-2'></span>Add FAQ Category</button>
              </div>
                
                <div className='themeinput mb-3 mt-4'>
                    <input type="text" placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
                </div>
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={vals}/>
            </div>
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default FaqCategory