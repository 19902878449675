import React, { useState } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'



const FaqCategoryModal = (props) =>{
   const[faqcategory, setFaqCategory] = useState(true)
 




   return(
    <>
    <Modal size="md" show={faqcategory}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll'>
       
      <div className='mb-4 themeinput'>
        <h5 className='headfont mb-4'>Edit FAQ Category</h5>

        <div className='w-full mb-3 '>
                <div className='w-full mb-3'>
                <p className='mb-2' >Category name</p>
                <div className=' '>
                   <input type="text" className='form-control' placeholder='test' />
                </div>
            </div>
                <div className=''>
                <p className='mb-2' >status</p>

                    <select className='form-select'>
                        <option>Activate</option>
                        <option>Deactive</option>
                    </select>
                </div>
          </div>
          
      
         
       
       <button className='btn btn-theme mt-4'>Submit</button>





   
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default FaqCategoryModal