import Validator from "validator";
import isEmpty from "is-empty";

export const validateUserRegister = (data) => {

    let errors = {};
    let isValid = true;

    data.user_name = !isEmpty(data.user_name) ? data.user_name : "";
    data.email = !isEmpty(data.email) ? data.email : "";
    data.password = !isEmpty(data.password) ? data.password : "";
    data.confirmpassword = !isEmpty(data.confirmpassword) ? data.confirmpassword : "";


    // if (Validator.isEmpty(data.first_name)) {
    //     isValid = false;
    //     errors.first_name = "Field is required";
    // }
    //    if (!Validator.equals(data.user_name, data.user_name.toLocaleLowerCase())) {
    //         isValid = false;
    //         errors.user_name = "Only Small Letters Characters are allowed";
    //     }
    // if (Validator.isEmpty(data.last_name)) {
    //     isValid = false;
    //     errors.last_name = "Field is required";
    // }
    if (Validator.isEmpty(data.email)) {
        isValid = false;
        errors.email = "Field is required";
    }
    var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    if (!pattern.test(data.email)) {
        isValid = false;
        errors.email = "Email is not Valid";
    }
    var passwordpattern = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/);
    if (!passwordpattern.test(data.password)) {
        isValid = false;
        errors.password = " Use 8 or more characters with a mix of letters, numbers & symbols";
    }
    if (Validator.isEmpty(data.password)) {
        isValid = false;
        errors.password = "Password field is required";
    }
    if (Validator.isEmpty(data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Confirm password field is required";
    }
    if (!Validator.isLength(data.password, { min: 8, max: 30 })) {
        isValid = false;
        errors.password = "Password must be at least 8 characters";
    }
    if (!Validator.equals(data.password, data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Passwords must match";
    }
    return {
        errors,
        isValid: isEmpty(errors)

    };
};


export const validateAssetAdd = (data) => {
    console.log('datttttttttttttttttt', data)
    console.log('asdfasjhdfas', data.type)
    var imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;

    let errors = {};
    let isValid = true;


    if (Validator.isEmpty(data?.type)) {
        isValid = false;
        errors.type = "Token field is required";
    }

    if (Validator.isEmpty(data?.network)) {
        isValid = false;
        errors.network = "Network field is required";
    }

    if (data.type == 'crypto' || data.type == 'token') {
        if (Validator.isEmpty(data?.tokenAddress)) {
            isValid = false;
            errors.tokenAddress = "TokenAddress field is required";
        }
    }


    if (Validator.isEmpty(data?.displayname)) {
        isValid = false;
        errors.symbol = "Asset Symbol field is required";
    }

    if (Validator.isEmpty(data?.name)) {
        isValid = false;
        errors.name = "Asset Name field is required";
    }

    if (Validator.isEmpty(data?.decimal)) {
        isValid = false;
        errors.decimal = "Decimal field is required";
    }
    if (data?.image && data?.image?.size) {
        {

            if (data?.image?.size > 1000000) {   // 10 MB

                isValid = false;
                errors.image = "TOO_LARGE"
            }
            else if (!imageFormat.test(data?.image?.name)) {

                isValid = false;
                errors.image = "INVALID_IMAGE"
            }
            else if (data?.image == '') {

                isValid = false;
                errors.image = "Asset Imagefield is required";
            }

        }
    }
    else if (data?.image == '') {
        isValid = false;
        errors.image = "Asset Imagefield is required";
    }



    // if (Validator.isEmpty(data?.image)) {
    //     isValid = false;
    //     errors.image = "Asset Imagefield is required";
    // }
    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export const ValidateUpdateToken = (data) => {

    var imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;
    let errors = {};
    let isValid = true;
    if (Validator.isEmpty(data?.type)) {
        isValid = false;
        errors.type = "TokenAddress field is required";
    }

    if (data?.type == 'token') {
        if (Validator.isEmpty(data?.tokenAddress)) {
            isValid = false;
            errors.tokenAddress = "TokenAddress field is required";
        }
    }


    if (Validator.isEmpty(data?.type)) {
        isValid = false;
        errors.type = "Asset Type field is required";
    }

    if (Validator.isEmpty(data?.displayname)) {
        isValid = false;
        errors.displayname = "Asset Symbol field is required";
    }

    if (Validator.isEmpty(data?.name)) {
        isValid = false;
        errors.name = "Asset Name field is required";
    }

    if (data?.image && data?.image?.size) {
        {

            if (data?.image?.size > 1000000) {   // 10 MB

                isValid = false;
                errors.image = "TOO_LARGE"
            }
            else if (!imageFormat.test(data?.image?.name)) {

                isValid = false;
                errors.image = "INVALID_IMAGE"
            }
            else if (data?.image == ' ') {

                isValid = false;
                errors.image = "Asset Imagefield is required";
            }

        }
    }
    else if (Validator.isEmpty(data?.image) || data?.image == '') {
        isValid = false;
        errors.image = "Asset Imagefield is required";
    }
    return {
        errors,
        isValid: isEmpty(errors)
    };


}
export const validateUserPassword = (data) => {


    let errors = {};
    let isValid = true;
    data.password = !isEmpty(data.password) ? data.password : "";
    data.confirmpassword = !isEmpty(data.confirmpassword) ? data.confirmpassword : "";

    var passwordpattern = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/);
    if (!passwordpattern.test(data.password)) {
        isValid = false;
        errors.password = " Use 8 or more characters with a mix of letters, numbers & symbols";
    }

    if (Validator.isEmpty(data.password)) {
        isValid = false;
        errors.password = "Password field is required";
    }
    if (Validator.isEmpty(data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Confirm password field is required";
    }
    if (!Validator.isLength(data.password, { min: 8, max: 30 })) {
        isValid = false;
        errors.password = "Password must be at least 8 characters";
    }
    if (!Validator.equals(data.password, data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Passwords must match";
    }
    return {
        errors,
        isValid: isEmpty(errors)

    };
};

export const validaterequestreset = (data) => {


    let errors = {};
    let isValid = true;
    data.email = !isEmpty(data.email) ? data.email : "";
    if (!Validator.isEmail(data.email)) {
        isValid = false;
        errors.email = "Email is invalid";
    }
    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export const validateForgotPassword = (data) => {


    let errors = {};
    let isValid = true;
    data.password = !isEmpty(data.password) ? data.password : "";
    data.confirmpassword = !isEmpty(data.confirmpassword) ? data.confirmpassword : "";

    var passwordpattern = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/);
    if (!passwordpattern.test(data.password)) {
        isValid = false;
        errors.password = " Use 8 or more characters with a mix of letters, numbers & symbols";
    }
    if (Validator.isEmpty(data.password)) {
        isValid = false;
        errors.password = "Password field is required";
    }
    if (Validator.isEmpty(data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Confirm password field is required";
    }
    if (!Validator.equals(data.password, data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Passwords must match";
    }
    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export const validatewithdrawForgotPassword = (data) => {


    let errors = {};
    let isValid = true;
    data.withdrawPassword = !isEmpty(data.withdrawPassword) ? data.withdrawPassword : "";
    data.confirmpassword = !isEmpty(data.confirmpassword) ? data.confirmpassword : "";

    var passwordpattern = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/);
    if (!passwordpattern.test(data.withdrawPassword)) {
        isValid = false;
        errors.withdrawPassword = " Use 8 or more characters with a mix of letters, numbers & symbols";
    }
    if (Validator.isEmpty(data.withdrawPassword)) {
        isValid = false;
        errors.withdrawPassword = "Password field is required";
    }
    if (Validator.isEmpty(data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Confirm password field is required";
    }
    if (!Validator.equals(data.withdrawPassword, data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Passwords must match";
    }
    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export const validatewithdrawPassword = (data) => {


    let errors = {};
    let isValid = true;
    data.withdrawPassword = !isEmpty(data.withdrawPassword) ? data.withdrawPassword : "";
    data.withdrawconfirmpassword = !isEmpty(data.withdrawconfirmpassword) ? data.withdrawconfirmpassword : "";


    var passwordpattern = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/);
    if (!passwordpattern.test(data.withdrawPassword)) {
        isValid = false;
        errors.withdrawPassword = " Use 8 or more characters with a mix of letters, numbers & symbols";
    }

    if (!Validator.equals(data.withdrawPassword, data.withdrawconfirmpassword)) {
        isValid = false;
        errors.withdrawconfirmpassword = "Passwords must match";
    }
    return {
        errors,
        isValid: isEmpty(errors)

    };
};

export const searchedBankValue = async (banklist, searchSymbol) => {
    const filteredData = await banklist.filter(value => ((value.bankname).toUpperCase()).includes(searchSymbol.toUpperCase()));
    return filteredData;
}

export const searchedBalanceValue = async (blancedata, searchSymbol) => {
    const filteredData = await blancedata.filter(value => ((value.symbol).toUpperCase()).includes(searchSymbol.toUpperCase()));
    return filteredData;
}






export const validateBankDetails = (data) => {





    let errors = {};
    let isValid = true;

    if (Validator.isEmpty(data.holdername)) {
        isValid = false;
        errors.holdername = "Field is required";
    }
    if (Validator.isEmpty(data.currency)) {
        isValid = false;
        errors.currency = "Field is required";
    }
    if (Validator.isEmpty(data.bankname)) {
        isValid = false;
        errors.bankname = "Field is required";
    }
    if (Validator.isEmpty(data.Accountnumber)) {
        isValid = false;
        errors.Accountnumber = "Field is required";
    }
    if (Validator.isEmpty(data.IFSCcode)) {
        isValid = false;
        errors.IFSCcode = "Field is required";
    }
    if (Validator.isEmpty(data.CountryList)) {
        isValid = false;
        errors.CountryList = "Field is required";
    }
    if (Validator.isEmpty(data.bankaddress)) {
        isValid = false;
        errors.bankaddress = "Field is required";
    }
    if (Validator.isEmpty(data.bankcity)) {
        isValid = false;
        errors.bankcity = "Field is required";
    }
    if (Validator.isEmpty(data.AccountType)) {
        isValid = false;
        errors.AccountType = "Field is required";
    }
    



    return {
        errors,
        isValid: isEmpty(errors)

    };
};

export const countryList = [
    {
        "name": "Afghanistan",
        "dial_code": "+93",
        "code": "AF"
    },
    {
        "name": "Aland Islands",
        "dial_code": "+358",
        "code": "AX"
    },
    {
        "name": "Albania",
        "dial_code": "+355",
        "code": "AL"
    },
    {
        "name": "Algeria",
        "dial_code": "+213",
        "code": "DZ"
    },
    {
        "name": "AmericanSamoa",
        "dial_code": "+1684",
        "code": "AS"
    },
    {
        "name": "Andorra",
        "dial_code": "+376",
        "code": "AD"
    },
    {
        "name": "Angola",
        "dial_code": "+244",
        "code": "AO"
    },
    {
        "name": "Anguilla",
        "dial_code": "+1264",
        "code": "AI"
    },
    {
        "name": "Antarctica",
        "dial_code": "+672",
        "code": "AQ"
    },
    {
        "name": "Antigua and Barbuda",
        "dial_code": "+1268",
        "code": "AG"
    },
    {
        "name": "Argentina",
        "dial_code": "+54",
        "code": "AR"
    },
    {
        "name": "Armenia",
        "dial_code": "+374",
        "code": "AM"
    },
    {
        "name": "Aruba",
        "dial_code": "+297",
        "code": "AW"
    },
    {
        "name": "Australia",
        "dial_code": "+61",
        "code": "AU"
    },
    {
        "name": "Austria",
        "dial_code": "+43",
        "code": "AT"
    },
    {
        "name": "Azerbaijan",
        "dial_code": "+994",
        "code": "AZ"
    },
    {
        "name": "Bahamas",
        "dial_code": "+1242",
        "code": "BS"
    },
    {
        "name": "Bahrain",
        "dial_code": "+973",
        "code": "BH"
    },
    {
        "name": "Bangladesh",
        "dial_code": "+880",
        "code": "BD"
    },
    {
        "name": "Barbados",
        "dial_code": "+1246",
        "code": "BB"
    },
    {
        "name": "Belarus",
        "dial_code": "+375",
        "code": "BY"
    },
    {
        "name": "Belgium",
        "dial_code": "+32",
        "code": "BE"
    },
    {
        "name": "Belize",
        "dial_code": "+501",
        "code": "BZ"
    },
    {
        "name": "Benin",
        "dial_code": "+229",
        "code": "BJ"
    },
    {
        "name": "Bermuda",
        "dial_code": "+1441",
        "code": "BM"
    },
    {
        "name": "Bhutan",
        "dial_code": "+975",
        "code": "BT"
    },
    {
        "name": "Bolivia, Plurinational State of",
        "dial_code": "+591",
        "code": "BO"
    },
    {
        "name": "Bosnia and Herzegovina",
        "dial_code": "+387",
        "code": "BA"
    },
    {
        "name": "Botswana",
        "dial_code": "+267",
        "code": "BW"
    },
    {
        "name": "Brazil",
        "dial_code": "+55",
        "code": "BR"
    },
    {
        "name": "British Indian Ocean Territory",
        "dial_code": "+246",
        "code": "IO"
    },
    {
        "name": "Brunei Darussalam",
        "dial_code": "+673",
        "code": "BN"
    },
    {
        "name": "Bulgaria",
        "dial_code": "+359",
        "code": "BG"
    },
    {
        "name": "Burkina Faso",
        "dial_code": "+226",
        "code": "BF"
    },
    {
        "name": "Burundi",
        "dial_code": "+257",
        "code": "BI"
    },
    {
        "name": "Cambodia",
        "dial_code": "+855",
        "code": "KH"
    },
    {
        "name": "Cameroon",
        "dial_code": "+237",
        "code": "CM"
    },
    {
        "name": "Canada",
        "dial_code": "+1",
        "code": "CA"
    },
    {
        "name": "Cape Verde",
        "dial_code": "+238",
        "code": "CV"
    },
    {
        "name": "Cayman Islands",
        "dial_code": "+ 345",
        "code": "KY"
    },
    {
        "name": "Central African Republic",
        "dial_code": "+236",
        "code": "CF"
    },
    {
        "name": "Chad",
        "dial_code": "+235",
        "code": "TD"
    },
    {
        "name": "Chile",
        "dial_code": "+56",
        "code": "CL"
    },
    {
        "name": "China",
        "dial_code": "+86",
        "code": "CN"
    },
    {
        "name": "Christmas Island",
        "dial_code": "+61",
        "code": "CX"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "dial_code": "+61",
        "code": "CC"
    },
    {
        "name": "Colombia",
        "dial_code": "+57",
        "code": "CO"
    },
    {
        "name": "Comoros",
        "dial_code": "+269",
        "code": "KM"
    },
    {
        "name": "Congo",
        "dial_code": "+242",
        "code": "CG"
    },
    {
        "name": "Congo, The Democratic Republic of the Congo",
        "dial_code": "+243",
        "code": "CD"
    },
    {
        "name": "Cook Islands",
        "dial_code": "+682",
        "code": "CK"
    },
    {
        "name": "Costa Rica",
        "dial_code": "+506",
        "code": "CR"
    },
    {
        "name": "Cote d'Ivoire",
        "dial_code": "+225",
        "code": "CI"
    },
    {
        "name": "Croatia",
        "dial_code": "+385",
        "code": "HR"
    },
    {
        "name": "Cuba",
        "dial_code": "+53",
        "code": "CU"
    },
    {
        "name": "Cyprus",
        "dial_code": "+357",
        "code": "CY"
    },
    {
        "name": "Czech Republic",
        "dial_code": "+420",
        "code": "CZ"
    },
    {
        "name": "Denmark",
        "dial_code": "+45",
        "code": "DK"
    },
    {
        "name": "Djibouti",
        "dial_code": "+253",
        "code": "DJ"
    },
    {
        "name": "Dominica",
        "dial_code": "+1767",
        "code": "DM"
    },
    {
        "name": "Dominican Republic",
        "dial_code": "+1849",
        "code": "DO"
    },
    {
        "name": "Ecuador",
        "dial_code": "+593",
        "code": "EC"
    },
    {
        "name": "Egypt",
        "dial_code": "+20",
        "code": "EG"
    },
    {
        "name": "El Salvador",
        "dial_code": "+503",
        "code": "SV"
    },
    {
        "name": "Equatorial Guinea",
        "dial_code": "+240",
        "code": "GQ"
    },
    {
        "name": "Eritrea",
        "dial_code": "+291",
        "code": "ER"
    },
    {
        "name": "Estonia",
        "dial_code": "+372",
        "code": "EE"
    },
    {
        "name": "Ethiopia",
        "dial_code": "+251",
        "code": "ET"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "dial_code": "+500",
        "code": "FK"
    },
    {
        "name": "Faroe Islands",
        "dial_code": "+298",
        "code": "FO"
    },
    {
        "name": "Fiji",
        "dial_code": "+679",
        "code": "FJ"
    },
    {
        "name": "Finland",
        "dial_code": "+358",
        "code": "FI"
    },
    {
        "name": "France",
        "dial_code": "+33",
        "code": "FR"
    },
    {
        "name": "French Guiana",
        "dial_code": "+594",
        "code": "GF"
    },
    {
        "name": "French Polynesia",
        "dial_code": "+689",
        "code": "PF"
    },
    {
        "name": "Gabon",
        "dial_code": "+241",
        "code": "GA"
    },
    {
        "name": "Gambia",
        "dial_code": "+220",
        "code": "GM"
    },
    {
        "name": "Georgia",
        "dial_code": "+995",
        "code": "GE"
    },
    {
        "name": "Germany",
        "dial_code": "+49",
        "code": "DE"
    },
    {
        "name": "Ghana",
        "dial_code": "+233",
        "code": "GH"
    },
    {
        "name": "Gibraltar",
        "dial_code": "+350",
        "code": "GI"
    },
    {
        "name": "Greece",
        "dial_code": "+30",
        "code": "GR"
    },
    {
        "name": "Greenland",
        "dial_code": "+299",
        "code": "GL"
    },
    {
        "name": "Grenada",
        "dial_code": "+1473",
        "code": "GD"
    },
    {
        "name": "Guadeloupe",
        "dial_code": "+590",
        "code": "GP"
    },
    {
        "name": "Guam",
        "dial_code": "+1671",
        "code": "GU"
    },
    {
        "name": "Guatemala",
        "dial_code": "+502",
        "code": "GT"
    },
    {
        "name": "Guernsey",
        "dial_code": "+44",
        "code": "GG"
    },
    {
        "name": "Guinea",
        "dial_code": "+224",
        "code": "GN"
    },
    {
        "name": "Guinea-Bissau",
        "dial_code": "+245",
        "code": "GW"
    },
    {
        "name": "Guyana",
        "dial_code": "+595",
        "code": "GY"
    },
    {
        "name": "Haiti",
        "dial_code": "+509",
        "code": "HT"
    },
    {
        "name": "Holy See (Vatican City State)",
        "dial_code": "+379",
        "code": "VA"
    },
    {
        "name": "Honduras",
        "dial_code": "+504",
        "code": "HN"
    },
    {
        "name": "Hong Kong",
        "dial_code": "+852",
        "code": "HK"
    },
    {
        "name": "Hungary",
        "dial_code": "+36",
        "code": "HU"
    },
    {
        "name": "Iceland",
        "dial_code": "+354",
        "code": "IS"
    },
    {
        "name": "India",
        "dial_code": "+91",
        "code": "IN"
    },
    {
        "name": "Indonesia",
        "dial_code": "+62",
        "code": "ID"
    },
    {
        "name": "Iran, Islamic Republic of Persian Gulf",
        "dial_code": "+98",
        "code": "IR"
    },
    {
        "name": "Iraq",
        "dial_code": "+964",
        "code": "IQ"
    },
    {
        "name": "Ireland",
        "dial_code": "+353",
        "code": "IE"
    },
    {
        "name": "Isle of Man",
        "dial_code": "+44",
        "code": "IM"
    },
    {
        "name": "Israel",
        "dial_code": "+972",
        "code": "IL"
    },
    {
        "name": "Italy",
        "dial_code": "+39",
        "code": "IT"
    },
    {
        "name": "Jamaica",
        "dial_code": "+1876",
        "code": "JM"
    },
    {
        "name": "Japan",
        "dial_code": "+81",
        "code": "JP"
    },
    {
        "name": "Jersey",
        "dial_code": "+44",
        "code": "JE"
    },
    {
        "name": "Jordan",
        "dial_code": "+962",
        "code": "JO"
    },
    {
        "name": "Kazakhstan",
        "dial_code": "+77",
        "code": "KZ"
    },
    {
        "name": "Kenya",
        "dial_code": "+254",
        "code": "KE"
    },
    {
        "name": "Kiribati",
        "dial_code": "+686",
        "code": "KI"
    },
    {
        "name": "Korea, Democratic People's Republic of Korea",
        "dial_code": "+850",
        "code": "KP"
    },
    {
        "name": "Korea, Republic of South Korea",
        "dial_code": "+82",
        "code": "KR"
    },
    {
        "name": "Kuwait",
        "dial_code": "+965",
        "code": "KW"
    },
    {
        "name": "Kyrgyzstan",
        "dial_code": "+996",
        "code": "KG"
    },
    {
        "name": "Laos",
        "dial_code": "+856",
        "code": "LA"
    },
    {
        "name": "Latvia",
        "dial_code": "+371",
        "code": "LV"
    },
    {
        "name": "Lebanon",
        "dial_code": "+961",
        "code": "LB"
    },
    {
        "name": "Lesotho",
        "dial_code": "+266",
        "code": "LS"
    },
    {
        "name": "Liberia",
        "dial_code": "+231",
        "code": "LR"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "dial_code": "+218",
        "code": "LY"
    },
    {
        "name": "Liechtenstein",
        "dial_code": "+423",
        "code": "LI"
    },
    {
        "name": "Lithuania",
        "dial_code": "+370",
        "code": "LT"
    },
    {
        "name": "Luxembourg",
        "dial_code": "+352",
        "code": "LU"
    },
    {
        "name": "Macao",
        "dial_code": "+853",
        "code": "MO"
    },
    {
        "name": "Macedonia",
        "dial_code": "+389",
        "code": "MK"
    },
    {
        "name": "Madagascar",
        "dial_code": "+261",
        "code": "MG"
    },
    {
        "name": "Malawi",
        "dial_code": "+265",
        "code": "MW"
    },
    {
        "name": "Malaysia",
        "dial_code": "+60",
        "code": "MY"
    },
    {
        "name": "Maldives",
        "dial_code": "+960",
        "code": "MV"
    },
    {
        "name": "Mali",
        "dial_code": "+223",
        "code": "ML"
    },
    {
        "name": "Malta",
        "dial_code": "+356",
        "code": "MT"
    },
    {
        "name": "Marshall Islands",
        "dial_code": "+692",
        "code": "MH"
    },
    {
        "name": "Martinique",
        "dial_code": "+596",
        "code": "MQ"
    },
    {
        "name": "Mauritania",
        "dial_code": "+222",
        "code": "MR"
    },
    {
        "name": "Mauritius",
        "dial_code": "+230",
        "code": "MU"
    },
    {
        "name": "Mayotte",
        "dial_code": "+262",
        "code": "YT"
    },
    {
        "name": "Mexico",
        "dial_code": "+52",
        "code": "MX"
    },
    {
        "name": "Micronesia, Federated States of Micronesia",
        "dial_code": "+691",
        "code": "FM"
    },
    {
        "name": "Moldova",
        "dial_code": "+373",
        "code": "MD"
    },
    {
        "name": "Monaco",
        "dial_code": "+377",
        "code": "MC"
    },
    {
        "name": "Mongolia",
        "dial_code": "+976",
        "code": "MN"
    },
    {
        "name": "Montenegro",
        "dial_code": "+382",
        "code": "ME"
    },
    {
        "name": "Montserrat",
        "dial_code": "+1664",
        "code": "MS"
    },
    {
        "name": "Morocco",
        "dial_code": "+212",
        "code": "MA"
    },
    {
        "name": "Mozambique",
        "dial_code": "+258",
        "code": "MZ"
    },
    {
        "name": "Myanmar",
        "dial_code": "+95",
        "code": "MM"
    },
    {
        "name": "Namibia",
        "dial_code": "+264",
        "code": "NA"
    },
    {
        "name": "Nauru",
        "dial_code": "+674",
        "code": "NR"
    },
    {
        "name": "Nepal",
        "dial_code": "+977",
        "code": "NP"
    },
    {
        "name": "Netherlands",
        "dial_code": "+31",
        "code": "NL"
    },
    {
        "name": "Netherlands Antilles",
        "dial_code": "+599",
        "code": "AN"
    },
    {
        "name": "New Caledonia",
        "dial_code": "+687",
        "code": "NC"
    },
    {
        "name": "New Zealand",
        "dial_code": "+64",
        "code": "NZ"
    },
    {
        "name": "Nicaragua",
        "dial_code": "+505",
        "code": "NI"
    },
    {
        "name": "Niger",
        "dial_code": "+227",
        "code": "NE"
    },
    {
        "name": "Nigeria",
        "dial_code": "+234",
        "code": "NG"
    },
    {
        "name": "Niue",
        "dial_code": "+683",
        "code": "NU"
    },
    {
        "name": "Norfolk Island",
        "dial_code": "+672",
        "code": "NF"
    },
    {
        "name": "Northern Mariana Islands",
        "dial_code": "+1670",
        "code": "MP"
    },
    {
        "name": "Norway",
        "dial_code": "+47",
        "code": "NO"
    },
    {
        "name": "Oman",
        "dial_code": "+968",
        "code": "OM"
    },
    {
        "name": "Pakistan",
        "dial_code": "+92",
        "code": "PK"
    },
    {
        "name": "Palau",
        "dial_code": "+680",
        "code": "PW"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "dial_code": "+970",
        "code": "PS"
    },
    {
        "name": "Panama",
        "dial_code": "+507",
        "code": "PA"
    },
    {
        "name": "Papua New Guinea",
        "dial_code": "+675",
        "code": "PG"
    },
    {
        "name": "Paraguay",
        "dial_code": "+595",
        "code": "PY"
    },
    {
        "name": "Peru",
        "dial_code": "+51",
        "code": "PE"
    },
    {
        "name": "Philippines",
        "dial_code": "+63",
        "code": "PH"
    },
    {
        "name": "Pitcairn",
        "dial_code": "+872",
        "code": "PN"
    },
    {
        "name": "Poland",
        "dial_code": "+48",
        "code": "PL"
    },
    {
        "name": "Portugal",
        "dial_code": "+351",
        "code": "PT"
    },
    {
        "name": "Puerto Rico",
        "dial_code": "+1939",
        "code": "PR"
    },
    {
        "name": "Qatar",
        "dial_code": "+974",
        "code": "QA"
    },
    {
        "name": "Romania",
        "dial_code": "+40",
        "code": "RO"
    },
    {
        "name": "Russia",
        "dial_code": "+7",
        "code": "RU"
    },
    {
        "name": "Rwanda",
        "dial_code": "+250",
        "code": "RW"
    },
    {
        "name": "Reunion",
        "dial_code": "+262",
        "code": "RE"
    },
    {
        "name": "Saint Barthelemy",
        "dial_code": "+590",
        "code": "BL"
    },
    {
        "name": "Saint Helena, Ascension and Tristan Da Cunha",
        "dial_code": "+290",
        "code": "SH"
    },
    {
        "name": "Saint Kitts and Nevis",
        "dial_code": "+1869",
        "code": "KN"
    },
    {
        "name": "Saint Lucia",
        "dial_code": "+1758",
        "code": "LC"
    },
    {
        "name": "Saint Martin",
        "dial_code": "+590",
        "code": "MF"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "dial_code": "+508",
        "code": "PM"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "dial_code": "+1784",
        "code": "VC"
    },
    {
        "name": "Samoa",
        "dial_code": "+685",
        "code": "WS"
    },
    {
        "name": "San Marino",
        "dial_code": "+378",
        "code": "SM"
    },
    {
        "name": "Sao Tome and Principe",
        "dial_code": "+239",
        "code": "ST"
    },
    {
        "name": "Saudi Arabia",
        "dial_code": "+966",
        "code": "SA"
    },
    {
        "name": "Senegal",
        "dial_code": "+221",
        "code": "SN"
    },
    {
        "name": "Serbia",
        "dial_code": "+381",
        "code": "RS"
    },
    {
        "name": "Seychelles",
        "dial_code": "+248",
        "code": "SC"
    },
    {
        "name": "Sierra Leone",
        "dial_code": "+232",
        "code": "SL"
    },
    {
        "name": "Singapore",
        "dial_code": "+65",
        "code": "SG"
    },
    {
        "name": "Slovakia",
        "dial_code": "+421",
        "code": "SK"
    },
    {
        "name": "Slovenia",
        "dial_code": "+386",
        "code": "SI"
    },
    {
        "name": "Solomon Islands",
        "dial_code": "+677",
        "code": "SB"
    },
    {
        "name": "Somalia",
        "dial_code": "+252",
        "code": "SO"
    },
    {
        "name": "South Africa",
        "dial_code": "+27",
        "code": "ZA"
    },
    {
        "name": "South Sudan",
        "dial_code": "+211",
        "code": "SS"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "dial_code": "+500",
        "code": "GS"
    },
    {
        "name": "Spain",
        "dial_code": "+34",
        "code": "ES"
    },
    {
        "name": "Sri Lanka",
        "dial_code": "+94",
        "code": "LK"
    },
    {
        "name": "Sudan",
        "dial_code": "+249",
        "code": "SD"
    },
    {
        "name": "Suriname",
        "dial_code": "+597",
        "code": "SR"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "dial_code": "+47",
        "code": "SJ"
    },
    {
        "name": "Swaziland",
        "dial_code": "+268",
        "code": "SZ"
    },
    {
        "name": "Sweden",
        "dial_code": "+46",
        "code": "SE"
    },
    {
        "name": "Switzerland",
        "dial_code": "+41",
        "code": "CH"
    },
    {
        "name": "Syrian Arab Republic",
        "dial_code": "+963",
        "code": "SY"
    },
    {
        "name": "Taiwan",
        "dial_code": "+886",
        "code": "TW"
    },
    {
        "name": "Tajikistan",
        "dial_code": "+992",
        "code": "TJ"
    },
    {
        "name": "Tanzania, United Republic of Tanzania",
        "dial_code": "+255",
        "code": "TZ"
    },
    {
        "name": "Thailand",
        "dial_code": "+66",
        "code": "TH"
    },
    {
        "name": "Timor-Leste",
        "dial_code": "+670",
        "code": "TL"
    },
    {
        "name": "Togo",
        "dial_code": "+228",
        "code": "TG"
    },
    {
        "name": "Tokelau",
        "dial_code": "+690",
        "code": "TK"
    },
    {
        "name": "Tonga",
        "dial_code": "+676",
        "code": "TO"
    },
    {
        "name": "Trinidad and Tobago",
        "dial_code": "+1868",
        "code": "TT"
    },
    {
        "name": "Tunisia",
        "dial_code": "+216",
        "code": "TN"
    },
    {
        "name": "Turkey",
        "dial_code": "+90",
        "code": "TR"
    },
    {
        "name": "Turkmenistan",
        "dial_code": "+993",
        "code": "TM"
    },
    {
        "name": "Turks and Caicos Islands",
        "dial_code": "+1649",
        "code": "TC"
    },
    {
        "name": "Tuvalu",
        "dial_code": "+688",
        "code": "TV"
    },
    {
        "name": "Uganda",
        "dial_code": "+256",
        "code": "UG"
    },
    {
        "name": "Ukraine",
        "dial_code": "+380",
        "code": "UA"
    },
    {
        "name": "United Arab Emirates",
        "dial_code": "+971",
        "code": "AE"
    },
    {
        "name": "United Kingdom",
        "dial_code": "+44",
        "code": "GB"
    },
    {
        "name": "United States",
        "dial_code": "+1",
        "code": "US"
    },
    {
        "name": "Uruguay",
        "dial_code": "+598",
        "code": "UY"
    },
    {
        "name": "Uzbekistan",
        "dial_code": "+998",
        "code": "UZ"
    },
    {
        "name": "Vanuatu",
        "dial_code": "+678",
        "code": "VU"
    },
    {
        "name": "Venezuela, Bolivarian Republic of Venezuela",
        "dial_code": "+58",
        "code": "VE"
    },
    {
        "name": "Vietnam",
        "dial_code": "+84",
        "code": "VN"
    },
    {
        "name": "Virgin Islands, British",
        "dial_code": "+1284",
        "code": "VG"
    },
    {
        "name": "Virgin Islands, U.S.",
        "dial_code": "+1340",
        "code": "VI"
    },
    {
        "name": "Wallis and Futuna",
        "dial_code": "+681",
        "code": "WF"
    },
    {
        "name": "Yemen",
        "dial_code": "+967",
        "code": "YE"
    },
    {
        "name": "Zambia",
        "dial_code": "+260",
        "code": "ZM"
    },
    {
        "name": "Zimbabwe",
        "dial_code": "+263",
        "code": "ZW"
    }
]

// length 164
// length 164
export const currency_list = [
    { "name": "Afghan Afghani", "code": "AFA" },
    { "name": "Albanian Lek", "code": "ALL" },
    { "name": "Algerian Dinar", "code": "DZD" },
    { "name": "Angolan Kwanza", "code": "AOA" },
    { "name": "Argentine Peso", "code": "ARS" },
    { "name": "Armenian Dram", "code": "AMD" },
    { "name": "Aruban Florin", "code": "AWG" },
    { "name": "Australian Dollar", "code": "AUD" },
    { "name": "Azerbaijani Manat", "code": "AZN" },
    { "name": "Bahamian Dollar", "code": "BSD" },
    { "name": "Bahraini Dinar", "code": "BHD" },
    { "name": "Bangladeshi Taka", "code": "BDT" },
    { "name": "Barbadian Dollar", "code": "BBD" },
    { "name": "Belarusian Ruble", "code": "BYR" },
    { "name": "Belgian Franc", "code": "BEF" },
    { "name": "Belize Dollar", "code": "BZD" },
    { "name": "Bermudan Dollar", "code": "BMD" },
    { "name": "Bhutanese Ngultrum", "code": "BTN" },
    { "name": "Bitcoin", "code": "BTC" },
    { "name": "Bolivian Boliviano", "code": "BOB" },
    { "name": "Bosnia-Herzegovina Convertible Mark", "code": "BAM" },
    { "name": "Botswanan Pula", "code": "BWP" },
    { "name": "Brazilian Real", "code": "BRL" },
    { "name": "British Pound Sterling", "code": "GBP" },
    { "name": "Brunei Dollar", "code": "BND" },
    { "name": "Bulgarian Lev", "code": "BGN" },
    { "name": "Burundian Franc", "code": "BIF" },
    { "name": "Cambodian Riel", "code": "KHR" },
    { "name": "Canadian Dollar", "code": "CAD" },
    { "name": "Cape Verdean Escudo", "code": "CVE" },
    { "name": "Cayman Islands Dollar", "code": "KYD" },
    { "name": "CFA Franc BCEAO", "code": "XOF" },
    { "name": "CFA Franc BEAC", "code": "XAF" },
    { "name": "CFP Franc", "code": "XPF" },
    { "name": "Chilean Peso", "code": "CLP" },
    { "name": "Chinese Yuan", "code": "CNY" },
    { "name": "Colombian Peso", "code": "COP" },
    { "name": "Comorian Franc", "code": "KMF" },
    { "name": "Congolese Franc", "code": "CDF" },
    { "name": "Costa Rican ColÃ³n", "code": "CRC" },
    { "name": "Croatian Kuna", "code": "HRK" },
    { "name": "Cuban Convertible Peso", "code": "CUC" },
    { "name": "Czech Republic Koruna", "code": "CZK" },
    { "name": "Danish Krone", "code": "DKK" },
    { "name": "Djiboutian Franc", "code": "DJF" },
    { "name": "Dominican Peso", "code": "DOP" },
    { "name": "East Caribbean Dollar", "code": "XCD" },
    { "name": "Egyptian Pound", "code": "EGP" },
    { "name": "Eritrean Nakfa", "code": "ERN" },
    { "name": "Estonian Kroon", "code": "EEK" },
    { "name": "Ethiopian Birr", "code": "ETB" },
    { "name": "Euro", "code": "EUR" },
    { "name": "Falkland Islands Pound", "code": "FKP" },
    { "name": "Fijian Dollar", "code": "FJD" },
    { "name": "Gambian Dalasi", "code": "GMD" },
    { "name": "Georgian Lari", "code": "GEL" },
    { "name": "German Mark", "code": "DEM" },
    { "name": "Ghanaian Cedi", "code": "GHS" },
    { "name": "Gibraltar Pound", "code": "GIP" },
    { "name": "Greek Drachma", "code": "GRD" },
    { "name": "Guatemalan Quetzal", "code": "GTQ" },
    { "name": "Guinean Franc", "code": "GNF" },
    { "name": "Guyanaese Dollar", "code": "GYD" },
    { "name": "Haitian Gourde", "code": "HTG" },
    { "name": "Honduran Lempira", "code": "HNL" },
    { "name": "Hong Kong Dollar", "code": "HKD" },
    { "name": "Hungarian Forint", "code": "HUF" },
    { "name": "Icelandic KrÃ³na", "code": "ISK" },
    { "name": "Indian Rupee", "code": "INR" },
    { "name": "Indonesian Rupiah", "code": "IDR" },
    { "name": "Iranian Rial", "code": "IRR" },
    { "name": "Iraqi Dinar", "code": "IQD" },
    { "name": "Israeli New Sheqel", "code": "ILS" },
    { "name": "Italian Lira", "code": "ITL" },
    { "name": "Jamaican Dollar", "code": "JMD" },
    { "name": "Japanese Yen", "code": "JPY" },
    { "name": "Jordanian Dinar", "code": "JOD" },
    { "name": "Kazakhstani Tenge", "code": "KZT" },
    { "name": "Kenyan Shilling", "code": "KES" },
    { "name": "Kuwaiti Dinar", "code": "KWD" },
    { "name": "Kyrgystani Som", "code": "KGS" },
    { "name": "Laotian Kip", "code": "LAK" },
    { "name": "Latvian Lats", "code": "LVL" },
    { "name": "Lebanese Pound", "code": "LBP" },
    { "name": "Lesotho Loti", "code": "LSL" },
    { "name": "Liberian Dollar", "code": "LRD" },
    { "name": "Libyan Dinar", "code": "LYD" },
    { "name": "Lithuanian Litas", "code": "LTL" },
    { "name": "Macanese Pataca", "code": "MOP" },
    { "name": "Macedonian Denar", "code": "MKD" },
    { "name": "Malagasy Ariary", "code": "MGA" },
    { "name": "Malawian Kwacha", "code": "MWK" },
    { "name": "Malaysian Ringgit", "code": "MYR" },
    { "name": "Maldivian Rufiyaa", "code": "MVR" },
    { "name": "Mauritanian Ouguiya", "code": "MRO" },
    { "name": "Mauritian Rupee", "code": "MUR" },
    { "name": "Mexican Peso", "code": "MXN" },
    { "name": "Moldovan Leu", "code": "MDL" },
    { "name": "Mongolian Tugrik", "code": "MNT" },
    { "name": "Moroccan Dirham", "code": "MAD" },
    { "name": "Mozambican Metical", "code": "MZM" },
    { "name": "Myanmar Kyat", "code": "MMK" },
    { "name": "Namibian Dollar", "code": "NAD" },
    { "name": "Nepalese Rupee", "code": "NPR" },
    { "name": "Netherlands Antillean Guilder", "code": "ANG" },
    { "name": "New Taiwan Dollar", "code": "TWD" },
    { "name": "New Zealand Dollar", "code": "NZD" },
    { "name": "Nicaraguan CÃ³rdoba", "code": "NIO" },
    { "name": "Nigerian Naira", "code": "NGN" },
    { "name": "North Korean Won", "code": "KPW" },
    { "name": "Norwegian Krone", "code": "NOK" },
    { "name": "Omani Rial", "code": "OMR" },
    { "name": "Pakistani Rupee", "code": "PKR" },
    { "name": "Panamanian Balboa", "code": "PAB" },
    { "name": "Papua New Guinean Kina", "code": "PGK" },
    { "name": "Paraguayan Guarani", "code": "PYG" },
    { "name": "Peruvian Nuevo Sol", "code": "PEN" },
    { "name": "Philippine Peso", "code": "PHP" },
    { "name": "Polish Zloty", "code": "PLN" },
    { "name": "Qatari Rial", "code": "QAR" },
    { "name": "Romanian Leu", "code": "RON" },
    { "name": "Russian Ruble", "code": "RUB" },
    { "name": "Rwandan Franc", "code": "RWF" },
    { "name": "Salvadoran ColÃ³n", "code": "SVC" },
    { "name": "Samoan Tala", "code": "WST" },
    { "name": "Saudi Riyal", "code": "SAR" },
    { "name": "Serbian Dinar", "code": "RSD" },
    { "name": "Seychellois Rupee", "code": "SCR" },
    { "name": "Sierra Leonean Leone", "code": "SLL" },
    { "name": "Singapore Dollar", "code": "SGD" },
    { "name": "Slovak Koruna", "code": "SKK" },
    { "name": "Solomon Islands Dollar", "code": "SBD" },
    { "name": "Somali Shilling", "code": "SOS" },
    { "name": "South African Rand", "code": "ZAR" },
    { "name": "South Korean Won", "code": "KRW" },
    { "name": "Special Drawing Rights", "code": "XDR" },
    { "name": "Sri Lankan Rupee", "code": "LKR" },
    { "name": "St. Helena Pound", "code": "SHP" },
    { "name": "Sudanese Pound", "code": "SDG" },
    { "name": "Surinamese Dollar", "code": "SRD" },
    { "name": "Swazi Lilangeni", "code": "SZL" },
    { "name": "Swedish Krona", "code": "SEK" },
    { "name": "Swiss Franc", "code": "CHF" },
    { "name": "Syrian Pound", "code": "SYP" },
    { "name": "São Tomé and Príncipe Dobra", "code": "STD" },
    { "name": "Tajikistani Somoni", "code": "TJS" },
    { "name": "Tanzanian Shilling", "code": "TZS" },
    { "name": "Thai Baht", "code": "THB" },
    { "name": "Tongan Pa'anga", "code": "TOP" },
    { "name": "Trinidad & Tobago Dollar", "code": "TTD" },
    { "name": "Tunisian Dinar", "code": "TND" },
    { "name": "Turkish Lira", "code": "TRY" },
    { "name": "Turkmenistani Manat", "code": "TMT" },
    { "name": "Ugandan Shilling", "code": "UGX" },
    { "name": "Ukrainian Hryvnia", "code": "UAH" },
    { "name": "United Arab Emirates Dirham", "code": "AED" },
    { "name": "Uruguayan Peso", "code": "UYU" },
    { "name": "US Dollar", "code": "USD" },
    { "name": "Uzbekistan Som", "code": "UZS" },
    { "name": "Vanuatu Vatu", "code": "VUV" },
    { "name": "Venezuelan BolÃvar", "code": "VEF" },
    { "name": "Vietnamese Dong", "code": "VND" },
    { "name": "Yemeni Rial", "code": "YER" },
    { "name": "Zambian Kwacha", "code": "ZMK" }
];



export const searchedValue = async (tokenList, searchSymbol) => {


    const filteredData = await tokenList.filter(value => ((value?.userId).toUpperCase()).includes(searchSymbol.toUpperCase()));
    return filteredData;
}


export const searchedToken = async (tokenList, searchSymbol) => {
    console.log("value", tokenList, searchSymbol);
    const filteredData = await tokenList.filter(value => ((value?.symbol).toUpperCase() || (value?.type).toUpperCase()).includes(searchSymbol.toUpperCase()))
    return filteredData;
}



