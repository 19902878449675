import React,{useState,useEffect,useRef} from 'react';

import Header from '../Header';
import Sidebar from '../Sidebar';

import DataTable from 'react-data-table-component';
import WithdrawModal from '../Modals/WithdrawModal';
import { getAllWithdrawrequest } from '../../Hooks/UseBackendAction/UseGetController';
import { CSVLink } from 'react-csv'
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { searchedValue } from '../../Hooks/UseValidation';

export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};



const Withdraw = () =>{
  
  const columns = [
    {
      name: "Request Date",
      selector: (vals) => (vals.createdAt).substring(0,10),
      sortable: true,
      minWidth:"130px"
    },
    {
      name: "User ID",
      selector: (vals) => vals.userId,
      sortable: true,
      minWidth:"150px"
    },
    {
        name: "Username",
        selector: (vals) => vals.user_name,
        sortable: true
      },
      
    {
        name: "To Account",
        selector: (vals) => vals.Accountnumber,
        sortable: true,
        minWidth:"250px"
      },
    {
        name: "Currency",
         selector: (vals) => vals.currency,
        sortable: true
      },
      {
        name: "Zarc Amount",
        selector: (vals) => vals.ZarcAmount,
        sortable: true,
        minWidth:"150px"
    }, {
      name: "Admin Fee",
      selector: (vals) => vals.adminFee,
      sortable: true,
      minWidth:"150px"
  },
    

    {
        name: "Fiat Amount",
        selector: (vals) => vals.amount,
        sortable: true,
        minWidth:"150px"
    },
    {
      name: "Status",
      selector: (vals) => vals.isApproved == null ? "Pending" : vals.isApproved == true ? "Approved" : "Cancelled",
      sortable: true,
      minWidth:"110px"
    },
    {
      name: "Admin Hash",
      selector: (vals) => ( parseFloat(vals.adminFee) > 0 && vals.adminHash != "")  ?
       <button  className='btn btn-theme iconbtn'><a target="_blank" href={vals.adminHash}><span className='fa fa-external-link'></span></a></button> : <button disabled={true} className='btn btn-theme iconbtn'><span className='fa fa-external-link'></span></button>,
      sortable: true,
      minWidth:"80px",
      maxWidth:"80px"
    },
   
    {
        name: "Action",
        selector: (vals) => <button className='btn btn-theme iconbtn' onClick={() => {editRecord(vals);setWithdraw(true)}}><span className='fa fa-check'></span></button>,
        sortable: true,
        minWidth:"80px",
        maxWidth:"80px"
    },
 
]  
const vals = [
  {
    date:"2022-09-27 17:43",
    buyerid: "62a1bc9",
    sellerid: "0x160cb17CcE20511062B",
    email: "BNB",
    google: "Coin",
    col1:"0.2",
    btn: "cancelled",
    col3:<button className='btn btn-theme iconbtn' onClick={() => setWithdraw(true)}><span className='fa fa-check'></span></button>,
  },

]
const[withdraws, setWithdraw] = useState(false)
const [records, setRecords] = useState([])
const [Currentrecords, setCurrentRecords] = useState({})
const csvLink = useRef()
const xlsLink = useRef()
const [Search, setSearch]   = useState("")
  const [isSearch, setisSearch] = useState(false)
  const [SearchCoinData, SetSearchCoinData] = useState([])

useEffect(() => {
  getData();

}, [])


const getTransactionData = async () => {

  csvLink.current.link.click()
}

const DownloadXLS = async () => {

  xlsLink.current.link.click()
}
const getData = async () => {
 
   var data = await getAllWithdrawrequest();
    setRecords(data.success ? data.data : [])
};

const editRecord = async (record) => {
  setCurrentRecords(record)
}

const handleChange = async (e) => {
        
  if((e.target.value).length > 0){
        const searchData = await searchedValue(records,e.target.value);
        
       
                                 SetSearchCoinData(searchData)
                                
                                 setisSearch(true)
  }else{
      setisSearch(false)
  }

}

const exportPDF = async() => {
 
  const result = await getAllWithdrawrequest()
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(13);

  const title = "Deposit History";
  const headers = [
    [
      "Username",
      "UserID",
      "Holdername",
      "Status",
      "currency",
      "amount",
      "ZarcAmount",
      "bankname",
      "Accountnumber",
      "Date",
      
      
    ],
  ];

  const data =
  
    result && result.data && result.data.length > 0 &&
    result.data.map((elt) => [
      elt.user_name,
      elt.userId,
      elt.holdername,
      elt.isApproved == null ? "Pending" : elt.isApproved == true ? "Approved" : "Canceled",
      elt.currency,
      elt.amount,
      elt.ZarcAmount,
      elt.bankname,
      elt.Accountnumber,
      elt.createdAt,
      
    ]);

  let content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save("Withdraw.pdf");
}
return(
<>
{withdraws && <WithdrawModal record={Currentrecords} onDismiss={() => setWithdraw(false)}/>}
 <div className='history dashboard transsec'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
            <h3 className='headfont '>Fiat Withdraw List </h3>
            </div>
            <div className='tabtable mt-5 potfolio box darkbox noshadow'>
                <h4 className='headfont'>Fiat Withdraw List</h4>
                <div className='mb-4'>
                {records && records.length > 0 ? 
                    <button onClick={exportPDF}className='btn btn-theme mt-3 me-2'>Download(PDF)</button> : <></>}
                    {records && records.length > 0 ? (
                      <>
                    <button onClick={DownloadXLS}className='btn btn-theme mt-3 me-2'>Download(XLS)</button>
                    <CSVLink
         data={records}
         filename='Withdraw.xls'
         className='hidden'
         ref={xlsLink}
         target='_blank'
      />

</>) : (
        ""
      )}
      {records && records.length > 0 ? (
                      <>
                    <button onClick={getTransactionData} className='btn btn-theme mt-3 me-2'>Download(CSV)</button>
                    <CSVLink
         data={records}
         filename='Withdraw.csv'
         className='hidden'
         ref={csvLink}
         target='_blank'
      />
      </>) : (
        ""
      )}
                </div>
                <div className=' themeinput mb-3'>
                    <input type="text"  onChange={(e)=>{setSearch(e.target.value);handleChange(e)}} placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
                </div>
                {!isSearch ?
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={records}/> : 
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={SearchCoinData}/>}
            </div>
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default Withdraw