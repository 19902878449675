import React,{useState} from 'react';
import { Button,Dropdown, Tab } from 'react-bootstrap';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';

import coin1 from '../Assets/Images/coin1.png'
import coin2 from '../Assets/Images/coin2.png'
import coin3 from '../Assets/Images/coin3.png'
import coin4 from '../Assets/Images/coin4.png'
import coin5 from '../Assets/Images/coin5.png'
import UpdateCurrency from './Modals/UpdateCurrency';
import AddCurrency from './Modals/AddCurrency';


export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};

const Currency = () =>{
  
  const columns = [
    {
      name: "Name",
      selector: (vals) => vals.date,
      sortable: true
    },
    {
      name: "Type",
      selector: (vals) => vals.userid,
      sortable: true
    },
    {
      name: "Coin",
       selector: (vals) => vals.email,
      sortable: true,
      minWidth:"210px"
    },
    {
      name: "Status",
      selector: (vals) => vals.google,
      sortable: true
    },
    {
      name: "Action",
      selector: (vals) => vals.btn,
      sortable: true,
      maxWidth: "80px",
      minWidth: "80px"

    },
   
     
     
   
      
   
]  
const vals = [
  {
    date:"Binance",
    userid: "5584154",
    email: "subbu2@yopmail.com",
    google: "Disabled",
    btn: <><button className='btn btn-theme iconbtn' onClick={() => setUpdateCurrency(true)}><span className='fa fa-edit'></span></button></>,
  },
  {
    date:"Binance",
    userid: "5584154",
    email: "subbu2@yopmail.com",
    google: "Disabled",
    btn: <><button className='btn btn-theme iconbtn' onClick={() => setUpdateCurrency(true)} ><span className='fa fa-edit'></span></button></>,
  },
  {
    date:"Binance",
    userid: "5584154",
    email: "subbu2@yopmail.com",
    google: "Disabled",
    btn: <><button className='btn btn-theme iconbtn'  onClick={() => setUpdateCurrency(true)}><span className='fa fa-edit'></span></button></>,
  },
  {
    date:"Binance",
    userid: "5584154",
    email: "subbu2@yopmail.com",
    google: "Disabled",
    btn: <><button className='btn btn-theme iconbtn' onClick={() => setUpdateCurrency(true)} ><span className='fa fa-edit'></span></button></>,
  },
  {
    date:"Binance",
    userid: "5584154",
    email: "subbu2@yopmail.com",
    google: "Disabled",
    btn: <><button className='btn btn-theme iconbtn'  onClick={() => setUpdateCurrency(true)}><span className='fa fa-edit'></span></button></>,
  },
  {
    date:"Binance",
    userid: "5584154",
    email: "subbu2@yopmail.com",
    google: "Disabled",
    btn: <><button className='btn btn-theme iconbtn' onClick={() => setUpdateCurrency(true)} ><span className='fa fa-edit'></span></button></>,
  },
]
const[updatecurrency, setUpdateCurrency] = useState(false)
const[addcurrency, setAddCurrency] = useState(false)


return(
<>
{updatecurrency && <UpdateCurrency onDismiss={() => setUpdateCurrency(false)}/>}
{addcurrency && <AddCurrency onDismiss={() => setAddCurrency(false)} />}
 <div className='history dashboard transsec'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
      <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
            <h3 className='headfont '>Currency List</h3>
            </div>
            <div className='tabtable mt-5 potfolio box darkbox noshadow'>
             <div className='d-flex jc-between mb-4'>
             <h4 className='headfont'>Currency List</h4>
             <button className='btn btn-theme'  onClick={() => setAddCurrency(true)}><span className='fa fa-plus'></span> Add Currency</button>
              </div>  
             
                <div className=' themeinput mb-3'>
                    <input type="text" placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
                </div>
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={vals}/>
               </div>
            
            
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default Currency