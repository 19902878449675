import React, { useState } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'
import { Addasset } from '../../Hooks/UseBackendAction/UseGetController';
import toast, { Toaster } from 'react-hot-toast';
import { style,iconTheme,position } from '../../Hooks/UseToast';
import { validateAssetAdd } from '../../Hooks/UseValidation';
const initialFormValue = {
    'tokenAddress' : '',
    'symbol':'',
    'name':'',
    'image':'',
    'type':'',
    'network' : '',
    'decimal' : '',
    'displayname' : ''
}

const AddFaq = (props) =>{

   const[addfaq, setaddFaq] = useState(true)
   const[state,setState] = useState(true)
   const[asset,setAsset] = useState(initialFormValue)
   const[errors, seterrors] = useState({})
   const[decimal, setdecimal] = useState("")
   const[displayname, setdisplayname] = useState("")
   const{ tokenAddress, symbol, name, image, type ,network}= asset;
   

//function
const handleChange = async(e)=>
{
    const name = e.target.name;
    const value = e.target.value;
    
  setAsset({...asset,[name]:value})
  
}


const handleselect = async (e)=>
{
  const value = e.target.value;
  setAsset({...asset,'type':value})
  const field = value == 'fiat' ? setState(false) : setState(true)
}
const networkselect = async (e)=>
{
  const value = e.target.value;
  setAsset({...asset,'network':value})
  
}
const onInputchange = async(e)=>
{
    setAsset({...asset,...{'image': e.target.files[0]}})

}

const handleSubmit = async()=>
{
  const reqData ={
    tokenAddress, symbol, name, image, type ,network,decimal,displayname
  }
  console.log('reqData',reqData)
  const {errors , isValid }= await validateAssetAdd(reqData);
  console.log('isValid',isValid,errors)
 if(!isValid)
 {
   seterrors(errors)
 }
 else
 {
const formdata = new FormData();
formdata.append('type',reqData.type)
formdata.append('tokenAddress',reqData.tokenAddress);
formdata.append('symbol',reqData.symbol);
formdata.append('name',reqData.name);
formdata.append('image',reqData.image)
formdata.append("network",reqData.network)
formdata.append("decimal",decimal)
formdata.append('displayname',displayname)
const data = await Addasset(formdata);
seterrors(errors)
try {
  console.log('erdataaaaaaaaaaaa',data)
    if(data?.success == true)
    {
      
         toast.success("Successfully Added !", {
      position:position.position,
       style:style,
       iconTheme: iconTheme,
   })
    props.onDismiss()
     props.record()
       
    }
    else if(data?.message == 'Asset Address Already Exists !')
    {
      toast.error("Asset Address Already existt", {
        position:position.position,
         style:style,
         iconTheme: iconTheme,
     })
    }
  
    
}
catch(err)
{
    console.log('err',err)
}
}
}


   return(
    <>
    <Modal size="md" show={addfaq}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll'>
       
      <div className='mb-4 themeinput'>
        <h5 className='headfont mb-4'> Add Assetss</h5>

        <div className='w-full mb-3'>
                
                <div className=''>
                <p className='mb-2' >Asset Type</p>
                <select className='form-control'  name ="type" onChange={(e)=>{handleselect(e);} }>
                  <option hidden>Select Asset Type</option>
                   {/* <option value="fiat">Fiat</option> */}
                    <option value = "crypto">Crypto</option>
                    <option value = "token">Token</option></select>
                </div>

                <div className=''>
                <p className='mb-2' >Select Network</p>
                <select className='form-control'  name ="type" onChange={(e)=>{networkselect(e);} }>
                  <option hidden>Select Asset Type</option>
                   <option value="bsc">BSC</option>
                    <option value = "solana">Solana</option>
                    <option value = "eth">Eth</option></select>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.type}</span>
                {state && <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Address</p>
                <div className='themeinput'>
                   <input type='text' className='form-control' name='tokenAddress' value={tokenAddress} onChange={(e)=>handleChange(e)}></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.tokenAddress}</span>
            </div>}
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Symbol</p>
                <div className='themeinput'>
                    <input type='text' name='symbol' className='form-control' value={displayname}  onChange={(e)=>setdisplayname(e.target.value)} ></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.displayname}</span>
            </div>
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Name</p>
                <div className='themeinput'>
                    <input type='text' name='name' className='form-control' value={name} onChange={(e)=>handleChange(e)}></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.name}</span>
            </div>
            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Decimal</p>
                <div className='themeinput'>
                    <input type='text' name='symbol' className='form-control' value={decimal}  onChange={(e)=>setdecimal(e.target.value)} ></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.decimal}</span>
            </div>



            <div className='w-full mb-3 mt-3'>
                <p className='mb-2' >Image</p>
                <div className='themefile'>
                  {asset?.image?.name ? <><img src={window.URL.createObjectURL(asset?.image)}/></>  : ' '}
                </div>
                <div className='themefiles'>
                <button className='chooseimg btn'>
                  Choose file</button>
                  <input type='file' className=' mt-2' name='image' onChange={(e)=>onInputchange(e)}></input>
                </div>
                <span className="text-danger f-12 d-block text-left">{errors.image}</span>
            </div>
          </div>
          
      
         
       
       <button className='btn btn-theme mt-4' onClick={()=>handleSubmit()} >Add</button>





   
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default AddFaq