import React, { useState } from 'react';
import { useEffect } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'
import bcoin from '../../Assets/Images/bcoin1.png'
import { UseadminReplymessage } from '../../Hooks/UseBackendAction/UseAdmin';

const ReplyContact = (props) =>{
   const[reply, setReply] = useState(true)
   const[adminreply, setadminReply] = useState("")


   useEffect(()=>{
    console.log("props",props);
   })


   const onSubmit = async (e) => {
    e.preventDefault();
    
    const UserData = {
        name: props?.record?.name,
        email:  props?.record?.email,
        message: props?.record?.comments,
        replymessage: adminreply,
      };
    await UseadminReplymessage(UserData)
       
};
   return(
    <>
    <Modal size="md" show={reply}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='px-lg-5 themescroll'>
       
      <form className='mb-4 mt-2 themeinput' onSubmit={onSubmit}>
            <h5 className='headfont mb-4'>Reply to contact</h5>
            <div className='details'> 
                <div className='d-flex flex-1'>
                    <p className=' text-muted'>Name</p>
                    <p className='trunkon200 '>{props?.record?.name}</p>
                </div>
                <div className='d-flex flex-1'>
                    <p className=' text-muted'>Email</p>
                    <p className='trunkon200 '>{props?.record?.email}</p>
                </div>
                
                <div className='d-sm-flex flex-1'>
                    <p className=' text-muted'>Message</p>
                    <p>{props?.record?.comments}</p>
                </div>
                <div className='d-sm-flex flex-1'>
                    <p className=' text-muted'>Reply</p>
                   <textarea rows={5}  required onChange={(e) => { setadminReply(e.target.value) }}  className='form-control'></textarea>
                </div>
               
              <div className='mt-4 text-end'>
                <button className='btn btn-theme'>Reply</button>
              </div>


            </div>
        
        
      </form>

    </Modal.Body>

</Modal>
  </>
   )
}

export default ReplyContact