import React,{useState,useEffect} from 'react';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';
import { validateUserPassword } from '../Hooks/UseValidation';
import { UseadminResetpassword } from '../Hooks/UseBackendAction/UseAdmin';
import {InputGroup, Form , Button} from 'react-bootstrap'



const ChangePwd = () =>{
   const [Currentpassword, setCurrentPasssword] = useState("")
   const [Newpassword, setNewpassword] = useState("")
   const [Confirmpassword, setConfirmPasssword] = useState("")
   const [passwordeye, setpasswordeye] = useState(false)
    const [passwordneweye, setpasswordneweye] = useState(false)
    const [passwordconfirmeye, setpasswordconfirmeye] = useState(false)
    const [errors, seterrors] = useState({})
    const [adminusername,setadminusername] = useState(sessionStorage.getItem("adminusername"))


    const showPassword = (e) => {

      var e = document.getElementById("Password");
      if (e.type === "password") {
          setpasswordeye(true)
          e.type = "text";
      } else {
          setpasswordeye(false)
          e.type = "password";
      }
  };
  const showNewPassword = (e) => {

      var e = document.getElementById("newPassword");
      if (e.type === "password") {
          setpasswordneweye(true)
          e.type = "text";
      } else {
          setpasswordneweye(false)
          e.type = "password";
      }
  };

  const showConfirmPassword = (e) => {

      var e = document.getElementById("confirmPassword");
      if (e.type === "password") {
          setpasswordconfirmeye(true)
          e.type = "text";
      } else {
          setpasswordconfirmeye(false)
          e.type = "password";
      }
  }

    const onSubmit = async () => {

      const UserpasswordData = {

          user_name: adminusername,
          currentpassword: Currentpassword,
          confirmpassword: Confirmpassword,
          password: Newpassword

      };
      const { errors, isValid } = await validateUserPassword(UserpasswordData);
      if (!isValid) {
          seterrors(errors)
      }
      else {
          await UseadminResetpassword(UserpasswordData)
      }
  };
return(
<>
 <div className='history dashboard transsec'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
             <h3 className='headfont '>Change password</h3>
            </div>
            <div className='row m-auto justify-content-center'>
                <div className='col-xl-5 col-lg-7'>
            <div className='tabtable mt-sm-5 potfolio box darkbox noshadow br-6'>
              <h4 className='headfont mb-4 px-sm-4'>Change password</h4>
              
              <div className='themeinput p-sm-4'>
                 {/* <div className='mb-4 d-flex jc-between'>
                    <label className='mb-2 text-light'>Email</label>
                   <button className='btn btn-theme'>Send OTP</button>
                 </div>
                 <div className='mb-3 '>
                    <label className='mb-2 text-light'>OTP</label>
                    <input type="text" className='form-control' />
                 </div> */}
                 <div className='mb-3 '>
                    <label className='mb-2 text-light'>Current Password</label>
                    <InputGroup className="mb-3 password" id="pass">
        <Form.Control
          value={Currentpassword}
          type="password"
          id="Password"
          onChange={(e) => { setCurrentPasssword(e.target.value) }}
          placeholder="Enter Current Password"
        />
          {!passwordeye ?
        <Button variant="outline-secondary" id="button-addon2" onClick={showPassword}>
          <span className='fa fa-eye-slash'></span>
        </Button> :
         <Button variant="outline-secondary" id="button-addon2" onClick={showPassword}>
         <span className='fa fa-eye'></span>
       </Button>}
      </InputGroup>
                  </div>

                  <div className='mb-3 '>
                    <label className='mb-2 text-light'>New Password</label>
                    <InputGroup className="mb-3 password" id="pass">
        <Form.Control
          id="newPassword" type="password"
          value={Newpassword}
          onChange={(e) => { setNewpassword(e.target.value) }}
          placeholder="Enter New password"
        />
          {!passwordneweye ?
        <Button variant="outline-secondary" id="button-addon2" onClick={showNewPassword}>
          <span className='fa fa-eye-slash'></span>
        </Button> :
        <Button variant="outline-secondary" id="button-addon2"onClick={showNewPassword}>
        <span className='fa fa-eye'></span>
      </Button>}
      </InputGroup>
                 <span className="text-danger f-12 mb-3 d-block text-left">{errors.password}</span>
                  </div>
         
                  <div className='mb-3 '>
                    <label className='mb-2 text-light'>Confirm New Password</label>
                    <InputGroup className="mb-3 password" id="pass">
        <Form.Control
          type="password"
          id="confirmPassword"
          placeholder="Enter Confirm password"
          value={Confirmpassword}
          onChange={(e) => { setConfirmPasssword(e.target.value) }}
        />
        {!passwordconfirmeye ?
        <Button variant="outline-secondary" id="button-addon2" onClick={showConfirmPassword}>
          <span className='fa fa-eye-slash'></span>
        </Button> :
         <Button variant="outline-secondary" id="button-addon2" onClick={showConfirmPassword}>
         <span className='fa fa-eye'></span>
       </Button>}
      </InputGroup>
                    <span className="text-danger f-12 mb-3 d-block text-left">{errors.confirmpassword}</span>
                  </div>        
                 
                 <div className='text-end'>
                  <button className='btn btn-theme mt-4' onClick={() => onSubmit()}>Reset</button></div>
                 
              </div>
              </div>
              </div>
            </div>
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default ChangePwd