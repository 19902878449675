import React, { useState } from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
import prof from '../../Assets/Images/prof1.jpg'

const EditProfile = (props) =>{
    const[profile, setProfile ]= useState(true)
    const[editprof , setEditProfimg] = useState(prof)

const getimage = (e) =>{
  const files = e.target.files[0];
    if(files) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files);
    fileReader.addEventListener("load", function () {
      setEditProfimg(this.result);
    });
  }
} 
   return(
    <>
    <Modal show={profile} centered scrollable className='modal_style thememodal'>
      <Modal.Header className='border-0 pb-0'>
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body>
      <div className='mb-4'>
        <div className='d-flex  align-items-center'>
            <div className='editimg'>
               <img src={editprof} />
               <Button variabnt="link">
                <input type="file" onClick={(e) => getimage(e)}/>
                <span className='fa fa-pencil'></span></Button>
            </div>
            <div>
                <h5 className='mb-0'>Profile</h5>
                <p className='text-muted pb-0 mb-0 f-14'>Lorem ipsum</p>
            </div>
        </div>
        <div className='d-sm-flex jc-between gap-2 w-full flex-1 mt-4'>
           <div className='wizardinput w-full'>
                    <label>First Name</label>
                    <input placeholder='Killer' className='form-control'/>
            </div>
            <div className='wizardinput w-full mt-sm-0 mt-3'>
                    <label>Last Name</label>
                    <input placeholder='Joe' className='form-control'/>
            </div>
            
        </div>
        <div className='d-sm-flex jc-between gap-2 w-full flex-1 mt-4'>
           <div className='wizardinput w-full'>
                    <label>Email</label>
                    <input placeholder='example@gmail.com' className='form-control'/>
            </div>
            <div className='wizardinput w-full mt-sm-0 mt-3'>
                    <label>Country</label>
                    <input placeholder='country' className='form-control'/>
            </div>
        </div>
        <div className='d-sm-flex jc-between gap-2 w-full flex-1 mt-4'>
           <div className='wizardinput w-full'>
                    <label>Password</label>
                    <input placeholder='Password' className='form-control'/>
            </div>
            <div className='wizardinput w-full mt-sm-0 mt-3'>
                    <label>Re-type password</label>
                    <input placeholder='Re-type password' className='form-control'/>
            </div>
            
        </div>
        <div className='wizardinput w-full mt-4'>
              <label>Change Password</label>
              <input placeholder='Password' className='form-control'/>
          </div>

        <div className='text-end mt-4 w-full'>
          <button className='btn btn-theme'>Update</button>
        </div>


       
        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default EditProfile