import React, { useState ,useEffect} from 'react';
import {Modal} from 'react-bootstrap'

import DataTable from 'react-data-table-component';
import { backurl } from '../../config/env';
import { UseisKyc } from '../../Hooks/UseBackendAction/UseAdmin';




const KycModal = (props) =>{
   const[kyc, setKyc] = useState(true)

   const [isKYCVerify,setisKYCVerify] = useState(null)
   const [kycfile,setfile] = useState([])
    const [username,setusername] = useState("")

    useEffect(() => {
       
        setfile(props?.record?.KYCFile)
        setusername((props?.record?.user_name))
      
        
    }, [isKYCVerify])

    const onisKycSubmit = async (value) => {
     

      const isKycdata = { user_name: username,isKYCVerifyAuth: value };
            await UseisKyc(isKycdata)
};

   const columns = [
    {
      name: "Type of Documents",
       selector: (vals) => vals.type,
      sortable: true
    },
    {
        name: "Documents",
        selector: (vals) => <a target="blank" href={`${backurl+`/Images/${vals.data}`}`}>Open</a>,
        sortable: true
      }
   
      
   
]  
const vals = [
  {
    img:"new",
    time: "new",

  },


]


   return(
    <>
    <Modal size="xl" show={kyc}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='p-lg-5 themescroll'>
      <div className='mb-4 '>
      <div className='d-sm-flex mt-3 jc-between align-items-center'>
       
       <h5 className='headfont'>KYC</h5>
       {/* <div className='themeinput mb-3'>
           <input type="text" placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
       </div>  */}
</div>
<div className=' tabtable  potfolio mt-4 '>
     <DataTable pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={kycfile}/>
</div>
</div>
<div className='text-end mt-4 w-full'>
          <button className='btn btn-theme me-2'  onClick={() => {  onisKycSubmit(true)}}>Accepted</button>
          <button className='btn btn-theme'  onClick={() => {  onisKycSubmit(false)}}>Decline</button>
</div>

    </Modal.Body>

</Modal>
  </>
   )
}

export default KycModal