import React, { useState } from 'react';
import {Modal, Form, Button} from 'react-bootstrap'

import prof1 from '../../Assets/Images/prof1.jpg'

import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; 
// import 'react-summernote/lang/summernote-ru-RU';

const UpdateCms = (props) =>{
  const onChange = (content) => {
        console.log('onChange', content);
      } 
   const[updatecms, setUpdateCms] = useState(true)

   return(
    <>
    <Modal size="lg" show={updatecms}  onHide={() => props.onDismiss()} centered scrollable  className='modal_style thememodal'>
  
      <Modal.Header className='border-0 pb-0'>
     
           <button type="button" className="btn close close_modal ms-auto text-white" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
      <Modal.Body className='px-lg-5 themescroll'>
       
      <div className='my-4 '>
  

    <div className=' noshadow br-6'>
    {/* <ReactSummernote
        value="Default value"
        options={{
          lang: 'ru-RU',
          height: 350,
          dialogsInBody: true,
          toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['fullscreen', 'codeview']]
          ]
        }}
        onChange={() => onChange()}
      /> */}
              <h4 className='headfont mb-4 px-4'>Update CMS</h4>
              
              <div className='themeinput p-sm-4 p-3'>
             
                     <div className='mb-3 w-full'>
                    <label className='mb-2 text-light'>Identifier</label>
                    <input type="text" className='form-control' placeholder='Trade_and_invest'/>

                 </div>
                 <div className='mb-3 w-full'>
                    <label className='mb-2 text-light'>Page Name</label>
                    <input type="text" className='form-control' placeholder='undefined'/>
                 </div>
                 <div className='mb-3 w-full'>
                    <label className='mb-2 text-light'>Content</label>
                 
                 </div>
              
                
              

                

              <div className='mt-5'><button className='btn btn-theme '>Add Currency</button></div> 
              </div>
           

</div>



   
        
      

        
      </div>
    </Modal.Body>

</Modal>
  </>
   )
}

export default UpdateCms