import React, { useEffect, useState } from 'react';

import Header from './Header';
import Sidebar from './Sidebar';


import DataTable from 'react-data-table-component';
import FaqListModal from './Modals/UpdateBank';
import AddFaq from './Modals/AddBank';
import { getAllAsset, getAllBankInfo, updateEnabled } from '../Hooks/UseBackendAction/UseGetController';
import { searchedToken } from '../Hooks/UseValidation';
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../Hooks/UseToast';
import { backurl } from '../config/env';
import { UseAdminBankStatus } from '../Hooks/UseBackendAction/UseAdmin';
export const data = {
  labels: ['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options: {
    cutout: "50%",
    plugins: {
      legend: {
        display: true,
        margin: 10,
        position: "right",
        align: "center",
        labels: {
          usePointStyle: true,
          padding: 20,
          color: "#fff"
        },
      },
    },
  }

};



const FaqList = () => {

  //state
  const [faqlist, setFaqList] = useState(false)
  const [addfaq, setAddFaq] = useState(false)
  const [record, setRecord] = useState([]);
  const [currentdata, setCurrentdata] = useState({})
 
  const [isSearch, setisSearch] = useState(false)

  const [state, setState] = useState(true)

  const getIsEnabled = async (tokenAddress, showEye) => {

    var data = await updateEnabled({ showEye, tokenAddress });

    if (data.data.data.isEnabled == true) {

      toast.success("Enabled", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
      getAssetData()
    } else {
      toast.success("Disabled", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      })
      getAssetData()
    }
  }

  const onisAuthSubmit = async (accounnumber,data) => {
   
    
      const isauthdata = { Accountnumber: accounnumber,status : data};
      await UseAdminBankStatus(isauthdata)
    
    
    };

  const columns = [
    {
      name: "Bank Name",
      selector: (record) => record.bankname,
      sortable: true,
      minWidth: "100px"
    },
    {
      name: "Account Number",
      selector: (record) => record.Accountnumber,
      sortable: true,
      minWidth: "150px"
    },
    
    {
      name: "Action",
      selector: (record) => (<div className='d-flex gap-1'>
        <button  className='btn btn-theme iconbtn' onClick={() => { setFaqList(true); setCurrentdata(record) }}>
          <span className='fa fa-edit'></span>
        </button>
        {record?.isEnabled == false ?
          <button className='btn btn-theme iconbtn'  onClick={async () => {onisAuthSubmit(record.Accountnumber,true) }}><span className='fa fa-eye-slash'></span>
          </button>
          :
          <button className='btn btn-theme iconbtn'
          onClick={async () => {onisAuthSubmit(record.Accountnumber,false)  }}><span className='fa fa-eye'></span>
          </button>} </div>),
      sortable: true,
      maxWidth: "80px"
    },

  ]

  //function
  const getAssetData = async () => {
    var data = await getAllBankInfo();
    
    setRecord(data.success == true ? data.data : [])
  };







  useEffect(() => {
    getAssetData();
  }, [])


  return (
    <>

      {addfaq && <AddFaq record={() => getAssetData()} onDismiss={() => setAddFaq(false)} />}
      {faqlist && <FaqListModal data={currentdata} record={() => getAssetData()} onDismiss={() => setFaqList(false)} />}
      <div className='history dashboard transsec'>
        <Header />
        <div className='page'>
          <div className='sidebar'><Sidebar /></div>
          <div className='content trans'>
            <div className='container-fluid container-theme p-4'>
              <div className='mb-4'>
                <h3 className='headfont '>VIEW BANK DETAILS</h3>
              </div>
              <div className='tabtable mt-5 potfolio box darkbox noshadow'>
                <div className='d-flex jc-between'>  <h4 className='headfont'>Bank List</h4>
                  <button className='btn btn-theme' onClick={() => setAddFaq(true)}><span className='fa fa-plus me-2'></span>Add Bank</button>
                </div>

             

                  <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={record} />
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FaqList