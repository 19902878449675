import React,{useState} from 'react';
import { Button,Dropdown, Tab } from 'react-bootstrap';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';

import coin1 from '../Assets/Images/coin1.png'
import coin2 from '../Assets/Images/coin2.png'
import coin3 from '../Assets/Images/coin3.png'
import coin4 from '../Assets/Images/coin4.png'
import coin5 from '../Assets/Images/coin5.png'


export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};

const PassBookHistory = () =>{
  
  const columns = [
    {
      name: "Date",
      selector: (vals) => vals.email,
      sortable: true
    },
    {
      name: "Coin",
      selector: (vals) => vals.iddoc,
      sortable: true
    },
    {
      name: "Tableid",
       selector: (vals) => vals.resdoc,
      sortable: true
    },  
    {
        name: "Useid",
         selector: (vals) => vals.col1,
        sortable: true
      },  
      {
        name: "Type",
         selector: (vals) => vals.col2,
        sortable: true
      },  
      {
        name: "Category",
         selector: (vals) => vals.col3,
        sortable: true
      },  
      {
        name: "Before Balace",
         selector: (vals) => vals.col4,
        sortable: true
      }, 
      {
        name: "After Balace",
         selector: (vals) => vals.col5,
        sortable: true
      }, 
      {
        name: "Amount",
         selector: (vals) => vals.col6,
        sortable: true
      },  
   
]  
const vals = [
  {
    email: "27-09-2022 12:15 pm",
    iddoc:"BNB",
    resdoc: "63329c16bcc1f7732af4b521",
    col1:"5584154",
    col2:"coin_deposit",
    col3:"credit",
    col4:"",
    col5:"0.2",
    col6:"0.2"
  }
 
]

return(
<>
 <div className='history dashboard transsec'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
            <h3 className='headfont '>Passbook History</h3>
            </div>
            
               <div className='tabtable mt-5 potfolio box darkbox'>
                <div className='d-flex jc-between'>
                    <h4 className='headfont'>Passbook History</h4>
                    <div className=' themeinput mb-3'>
                        <input type="text" placeholder="Filter in records..." className='form-control mw-300 ms-auto'/>
                    </div>
                </div>
              
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={vals}/>
               </div>
            
            
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default PassBookHistory