import React,{useState} from 'react';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';
import UpdateCms from './Modals/UpdateCms';



export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};

const Cms = () =>{
  
  const columns = [


    {
        name: "Page Name",
        selector: (vals) => vals.col1,
        sortable: true,
        minWidth:"160px"
    },
    {
      name: "Status",
      selector: (vals) => vals.btn,
      sortable: true
    },
   
    {
        name: "Action",
        selector: (vals) => vals.col3,
        sortable: true,
        
    },
]  
const vals = [
  {
 
    col1:"Trade_and_Invest",
    btn: "active",
    col3:<button className='btn btn-theme iconbtn' onClick={() => setUpdateCms(true)}><span className='fa fa-check'></span></button>,
  },

]
const[updatecms, setUpdateCms] = useState(false)

return(
<>
{updatecms && <UpdateCms onDismiss= {() => setUpdateCms(false)} />}
 <div className='history dashboard transsec'>
    <Header />
   <div className='page'>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
            <div className='mb-4'>
            <h3 className='headfont '>Cms List</h3>
            </div>
            <div className='tabtable mt-5 potfolio box darkbox noshadow'>
                <h4 className='headfont'>Cms List</h4>
               
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={vals}/>
            </div>
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default Cms