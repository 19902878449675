import React, { useState, useEffect, useRef } from 'react';

import Header from '../Header';
import Sidebar from '../Sidebar';

import DataTable from 'react-data-table-component';
import { getAllTransaction } from '../../Hooks/UseBackendAction/UseGetController';
import { CSVLink } from 'react-csv'
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { searchedValue } from '../../Hooks/UseValidation';


export const data = {
  labels: ['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options: {
    cutout: "50%",
    plugins: {
      legend: {
        display: true,
        margin: 10,
        position: "right",
        align: "center",
        labels: {
          usePointStyle: true,
          padding: 20,
          color: "#fff"
        },
      },
    },
  }

};

const TradeHistory = () => {

  const columns = [
    {
      name: "Date",
      selector: (vals) => vals.updatedAt.substring(0, 10),
      sortable: true,
      minWidth: "150px"
    },
    {
      name: "User ID",
      selector: (vals) => vals.userId,
      sortable: true,
      minWidth: "150px"
      
    },
    {
      name: "From",
      selector: (vals) => vals.from.substring(0, 5) + "..." + vals.from.substring(35, 42),
      sortable: true,
      minWidth: "200px"
    },
    {
      name: "To",
      selector: (vals) => vals.to.substring(0, 5) + "..." + vals.to.substring(35, 42),
      sortable: true,
      minWidth: "200px"
    },
    {
      name: "Amount",
      selector: (vals) => parseFloat(vals.amount).toFixed(2),
      sortable: true
      
    },
   
    {
      name: "Token",
      selector: (vals) => vals.symbol,
      sortable: true
    },
    
    {
      name: "Network",
      selector: (vals) => vals.network,
      sortable: true,
      maxWidth:"80"
    },
    {
      name: "Type",
      selector: (vals) => vals.type === "DEPOSIT" ? "Deposit" : vals.type === "Withdraw" ? "Withdraw" : "Swap",
      sortable: true,
      
    },
   
  {
    name: "Gas Fee",
    selector: (vals) =>  vals.type === "DEPOSIT" ? parseFloat(vals.adminGasFee).toFixed(7) : "-",
    sortable: true,
    maxWidth:"350px"
},
{
  name: "Admin Fee",
 
  selector: (vals) => (vals.adminfee),
  sortable: true
  
},
{
  name: "Admin Hash",
  selector: (vals) => (vals.type === "DEPOSIT" || vals.type === "Withdraw" && parseFloat(vals.adminfee) > 0 ) ?
   <button  className='btn btn-theme iconbtn'><a target="_blank" href={vals.adminFeehash}><span className='fa fa-external-link'></span></a></button> : <button disabled={true} className='btn btn-theme iconbtn'><span className='fa fa-external-link'></span></button>,
  sortable: true,
  minWidth:"80px",
  maxWidth:"80px"
},
{
  name: "Explorer",
  selector: (vals) => (vals.hash != "" && vals.type === "SWAP" || vals.hash != "" && vals.type === "DEPOSIT" || vals.hash != "" &&  vals.type === "Withdraw" ?

    vals.network === "Solana" ?
    <button  className='btn btn-theme iconbtn'><a target="_blank" href={(vals.type === "Withdraw" || vals.type === "DEPOSIT") ? vals.hash : "https://solscan.io/tx/"+ vals.hash}><span className='fa fa-external-link'></span></a></button>
   :

    vals.network === "BSC" ?
    <button  className='btn btn-theme iconbtn'><a target="_blank" href={(vals.type === "Withdraw" || vals.type === "DEPOSIT") ? vals.hash : "https://bscscan.com/tx/"+vals.hash}><span className='fa fa-external-link'></span></a></button>: 
    
    <button  className='btn btn-theme iconbtn'><a target="_blank" href={(vals.type === "Withdraw" || vals.type === "DEPOSIT") ? vals.hash : "https://etherscan.io/tx/"+vals.hash}><span className='fa fa-external-link'></span></a></button> : <></>
  ),
  sortable: true
},
]

  
  const [records, setRecords] = useState([])
  const csvLink = useRef()
  const xlsLink = useRef()
  const [Search, setSearch] = useState("")
  const [isSearch, setisSearch] = useState(false)
  const [SearchCoinData, SetSearchCoinData] = useState([])

  useEffect(() => {
    getData();

  }, [])
  const getData = async () => {

    var data = await getAllTransaction();
    console.log("data",data);

    setRecords(data.success ? data.data : [])
  };

  const getTransactionData = async () => {

    csvLink.current.link.click()
  }

  const DownloadXLS = async () => {

    xlsLink.current.link.click()
  }

  const handleChange = async (e) => {

    if ((e.target.value).length > 0) {
      const searchData = await searchedValue(records, e.target.value);

      console.log('data', searchData)
      SetSearchCoinData(searchData)

      setisSearch(true)
    } else {
      setisSearch(false)
    }

  }
  const exportPDF = async () => {

    const result = await getAllTransaction()
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    const title = "Trade History";
    const headers = [
      [
        "Date",
        "From",
        "To",
        "Amount",
        "Currency",
        "Network",
        "Type",
        "Gas Fee",
        "Admin Fee"

      ],
    ];

    const data =

      result && result.data && result.data.length > 0 &&
      result.data.map((elt) => [
        elt.updatedAt,
        elt.from,
        elt.to,
        elt.amount,
        elt.symbol,
        elt.network,
        elt.flow === "IN" ? "Deposit" : elt.flow === "OUT" ? "Withdraw" : "Exchange",
        elt.type === "DEPOSIT" ? parseFloat(elt.adminGasFee).toFixed(7) : "-",
        elt.adminfee


      ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("TradeHistory.pdf");
  }
  return (
    <>
      <div className='history dashboard transsec'>
        <Header />
        <div className='page'>
          <div className='sidebar'><Sidebar /></div>
          <div className='content trans'>
            <div className='container-fluid container-theme p-4'>
              <div className='mb-4'>
                <h3 className='headfont '>Trade History</h3>
              </div>
              <div className='tabtable mt-5 potfolio box darkbox noshadow'>
                <h4 className='headfont'>Trade History</h4>
                <div className='mb-4'>
                  {records && records.length > 0 ?
                    <button onClick={exportPDF} className='btn btn-theme mt-3 me-2'>Download(PDF)</button> : <></>}
                  {records && records.length > 0 ? (
                    <>
                      <button onClick={DownloadXLS} className='btn btn-theme mt-3 me-2'>Download(XLS)</button>
                      <CSVLink
                        data={records}
                        filename='Transaction.xls'
                        className='hidden'
                        ref={xlsLink}
                        target='_blank'
                      />
                    </>) : (
                    ""
                  )}

                  {records && records.length > 0 ? (
                    <>
                      <button onClick={getTransactionData} className='btn btn-theme mt-3 me-2'>Download(CSV)</button>
                      <CSVLink
                        data={records}
                        filename='Transaction.csv'
                        className='hidden'
                        ref={csvLink}
                        target='_blank'
                      />
                    </>) : (
                    ""
                  )}
                </div>
                <div className=' themeinput mb-3'>
                  <input type="text" onChange={(e) => { setSearch(e.target.value); handleChange(e) }} placeholder="Filter in records..." className='form-control mw-300 ms-auto' />
                </div>
                {!isSearch ?
                  <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={records} /> :
                  <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={SearchCoinData} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TradeHistory