import React,{useState,useEffect,useRef } from 'react';
import { Button,Dropdown, NavLink, Form,Tab } from 'react-bootstrap';

import Header from './Header';
import Sidebar from './Sidebar';

import DataTable from 'react-data-table-component';


import coin1 from '../Assets/Images/coin1.png'
import coin2 from '../Assets/Images/coin2.png'
import coin3 from '../Assets/Images/coin3.png'
import coin4 from '../Assets/Images/coin4.png'
import coin5 from '../Assets/Images/coin5.png'
import UserDetails from './Modals/UserDetails';
import Refferal from './Modals/Refferal';
import KycModal from './Modals/KycModal';
import { Link } from 'react-router-dom';
import { getAlluserInfo } from '../Hooks/UseBackendAction/UseGetController';
import { UseUpdateIsExisit,UseisAuth } from '../Hooks/UseBackendAction/UseAdmin';


export const data = {
  labels:['Token Sale 30%', 'Reserve Capital 20%', 'Founders 30%'],
  datasets: [
    {
      label: '# of Votes',
      data: [20, 19, 12 ],
      backgroundColor: [
        '#ff008e',
        '#eb00f8',
        '#f95323'
      ],
      borderColor: [
        '#ff008e',
          '#eb00f8',
          '#f95323'
      ],
      borderWidth: 1,
    },
  ],
  options:{
    cutout: "50%",
      plugins: {
        legend: {
          display: true,
          margin:10,
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            padding:20,
            color:"#fff"
          },
        },
      },
}   

};



const User = () =>{
  
  const columns = [
    {
      name: "Created date",
      selector: (vals) =>((vals.createdAt).substring(0,10)),
      sortable: true,
      sortable: true,
      minWidth: "160px"
    },
    {
      name: "User Id",
      selector: (vals) => vals.userId,
      sortable: true,
      minWidth: "140px"
    },
    {
      name: "Username",
      selector: (vals) => vals.user_name,
      sortable: true,
      minWidth: "140px"
    },
    {
      name: "Email",
       selector: (vals) => <span className='email_braek_td'>{vals.email}</span>,
      sortable: true,
      minWidth: "300px"
    },
    {
      name: "2FA Status",
      selector: (vals) => (vals.isAuth ? "On" : "Off"),
      sortable: true,
      minWidth: "150px",
   

    },
    {
      name: "2FA Edit",
      selector: (vals) =>
      <Form>
        <Form.Check 
          type="switch" className='custswitch'
          id="custom-switch"
          checked={vals.isAuth}
          onChange={async () => {vals.isAuth == true ?  onEdit2faStatus(vals.user_name,true): onEdit2faStatus(vals.user_name,false) }}
        />
      </Form>
        // <button className='btn btn-theme iconbtn diabled' ><span className='fa fa-eye-slash' ></span></button>
        ,
      
      sortable: true.valueOf,
      maxWidth: "80px",
      minWidth: "80px"
    },
    {
      name: "Asset",
      selector: (vals) => (vals.Holding.length > 0 ? <><button className='btn btn-theme iconbtn' ><span className='fa fa-eye' onClick={() =>{editRecord(vals); setUserDeatils(true)}}></span></button></> :
       <><button className='btn btn-theme iconbtn diabled' ><span className='fa fa-eye-slash' ></span></button></>),
      sortable: true,
      maxWidth: "80px",
      minWidth: "80px"
    },
   
    {
      name: "KYC",
      selector: (vals) =>(vals.KYCFile.length > 0 ?
        <><button className='btn btn-theme iconbtn' ><span className='fa fa-eye' onClick={() => {editRecord(vals);setKyc(true)}}></span></button></>:
        <><button className='btn btn-theme iconbtn diabled' ><span className='fa fa-eye-slash' ></span></button></>
      ),
      sortable: true.valueOf,
      maxWidth: "80px",
      minWidth: "80px"
    },
    {
      name: "KYC Status",
      selector: (vals) => (vals.KYCFile.length > 0) || vals.isKYCVerify  == "false" ? vals.isKYCVerify === "true" ?  "Verified" : (vals.isKYCVerify === null) || ( vals.isKYCVerify === "false"  && vals.KYCFile.length > 0 ) ? "pending":"Cancelled" : "No KYC",
      sortable: true,
      minWidth:"120px"
    },
    {
      name: "Active",
      selector: (vals) =>
      <Form>
        <Form.Check 
          type="switch" className='custswitch'
          id="custom-switch"
          checked={vals.isExists}
          onChange={async () => {vals.isExists == true ? onisAuthSubmit(vals.user_name,vals.userId,auth) : onisAuthSubmit(vals.user_name,vals.userId,isauth) }}
        />
      </Form>
        // <button className='btn btn-theme iconbtn diabled' ><span className='fa fa-eye-slash' ></span></button>
        ,
      
      sortable: true.valueOf,
      maxWidth: "80px",
      minWidth: "80px"
    },
    // {
    //   name: "Action",
    //   selector: (vals) => vals.btn,
    //   sortable: true
    // },
    // {
    //   name: "PassBook",
    //   selector: (vals) => vals.viewbtn,
    //   sortable: true,
    //   minWidth:"120px"
    // }
     ]  
const vals = [
  {
    date:"2022-26-09 10:41",
    userid: "5584154",
    email: "subbu2@yopmail.com",
    emailid: "verified",
    phno: "9876541230",
    google: "Disabled",
    asset: <><button className='btn btn-theme iconbtn' ><span className='fa fa-eye' onClick={() => setUserDeatils(true)}></span></button></>,
    refferal: <><button className='btn btn-theme iconbtn' ><span className='fa fa-eye' onClick={() => setRefferal(true)}></span></button></>,
    kyc: <><button className='btn btn-theme iconbtn' ><span className='fa fa-eye' onClick={() => setKyc(true)}></span></button></>,
    stat: "unverified",
    viewbtn: <><button className='btn btn-theme' ><Link to="/passbook" className=' nounder text-light'>View</Link> </button></>,
  },

 



]
const[userdeatils, setUserDeatils] = useState(false)
const[refferal, setRefferal] = useState(false)
const[kyc, setKyc] = useState(false)
const [records, setRecords] = useState([])
const [Currentrecords, setCurrentRecords] = useState({})
const csvLink = useRef()
const [isauth,setisauth] = useState(true)
const [auth,setauth] = useState(false)




useEffect(() => {
  getData();

}, [])
const getData = async () => {
  var data = await getAlluserInfo();
  
  setRecords(data.success ? data.data : [])
};

const editRecord = async (record) => {
  setCurrentRecords(record)
}


const onchangeCheck = (value) => {
  if (value == true) {
      setisauth(false)
  }
  else {
      setisauth(true)
  }
}

const onEdit2faStatus = async (name,value) => {

  const isauthdata = { user_name: name, isAuth: value };
  await UseisAuth(isauthdata)


};

// const onchangeCheck = () => {
//   if (isauth == true) {
//       setisauth(false)
//   }
//   else {
//       setisauth(true)
//   }
// }

const onisAuthSubmit = async (name,id,status) => {


  const isauthdata = { user_name: name,userId : id, isAuth: status};
  await UseUpdateIsExisit(isauthdata)


};
return(
<>
{userdeatils && <UserDetails record={Currentrecords} onDismiss={() => setUserDeatils(false)}/>}
{refferal && <Refferal onDismiss={() => setRefferal(false)}/>}
{kyc && <KycModal record={Currentrecords} onDismiss={() => setKyc(false)} />}
 <div className='history dashboard transsec'>
    <Header />
   <div className='page '>
   <div className='sidebar'><Sidebar /></div>
      <div className='content trans'>
         <div className='container-fluid container-theme p-4'>
              <div className='mb-4'>
                <h3 className='headfont '>User Management</h3>
              </div>
              <div className='tabtable mt-5 potfolio box darkbox'>
                <h4 className='headfont'>User Management</h4>
              <div className='usertabs'>
                <DataTable className='themescroll' pagination paginationIconPrevious="Previous" paginationIconNext="Next" columns={columns} data={records}/>
              </div>
          </div>
            
            
         </div>
      </div>
   </div>
 </div>
</>
)
}

export default User