import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap'
import { UseAdminApproveWithdraw } from '../../Hooks/UseBackendAction/UseAdmin';

const WithdrawModal = (props) => {
    const [withdraws, setWithdraw] = useState(true)
    const [showtoken, setShowToken] = useState(false)
    const [selected, setSelected] = useState("")
    const [accountno, setaccountno] = useState(props.record.Accountnumber)
    const [amount, setamount] = useState(props.record.amount)
    const [status, setStatus] = useState(props.record.isApproved)
    const [currency, setcurrency] = useState(props.record.currency)
    const [ZarcAmount, setZarcAmount] = useState(props.record.ZarcAmount)
    const [TowalletAddress, setTowalletAddress] = useState(props.record.walletAddress)
    const [withdrawsStatus, setWithdrawsStatus] = useState(false)
    const [declineValue, setDeclineValue] = useState("");
    const [err, setErr] = useState("");
    const [btn_status,setbtn_status] = useState("Approve")
    
    useEffect(() => {
        console.log("props>>>>>>", props);
    })

    const onAdminApprove = async (value) => {

        const isKycdata = {
            id: props.record._id,
            user_name: props.record.user_name,
            Currency: currency,
            amount: amount,
            isApproved: value,
            ZarcAmount: ZarcAmount,
            TowalletAddress: TowalletAddress,
            network: props?.record?.network,
            adminFee: props?.record?.adminFee,
            reason: declineValue,
            date: new Date(Date.now()).toDateString()
        };
        if(declineValue == "" && withdrawsStatus == true){
            setErr("* Reason is required")
        }else{
            await UseAdminApproveWithdraw(isKycdata)
            setbtn_status("Approving...")
        }
    };
    return (
        <>
            <Modal size="md" show={withdraws} onHide={() => props.onDismiss()} centered scrollable className='modal_style thememodal'>

                <Modal.Header className='border-0 pb-0'>

                    <button type="button" className="btn close close_modal ms-auto text-white" onClick={() => { props.onDismiss() }}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body className='p-lg-5 themescroll'>

                    <div className='mb-4 '>
                        <h5 className='headfont mb-4'>Details</h5>
                        <div className='details'>
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>To Account</p>
                                <p className='trunkon200'>{accountno}</p>
                            </div>
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Currency</p>
                                <p>{currency}</p>
                            </div>
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Network</p>
                                <p>{props?.record?.network} </p>
                            </div>
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Request Amount</p>
                                <p>{ZarcAmount}</p>
                            </div>
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Zarc Amount</p>
                                <p>{amount}</p>
                            </div>
                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Fee Amount</p>
                                <p>{props?.record?.adminFee} ZARC</p>
                            </div>


                            <div className='d-flex flex-1'>
                                <p className=' text-muted'>Status</p>
                                <p>{status == null ? "Pending" : status == true ? "Approved" : "Disapproved"}</p>
                            </div>
                            {  !withdrawsStatus &&<div className='text-end mt-4 w-full'>
                                <button className='btn btn-theme me-2' onClick={() => { onAdminApprove(true) }}>{btn_status}</button>
                                <button className='btn btn-theme' onClick={() => { setWithdrawsStatus(true) }}>Decline</button>
                            </div>}
                            {
                                withdrawsStatus &&
                                <div className='text-end mt-4 w-full themeinput'>
                                    {/* <input className='form-control mb-3' placeHolder="Reason for decline" value={declineValue} onChange={(e) => { setDeclineValue(e.target.value) }} /> */}
                                    <textarea rows="4" cols="50" className='depositmodal_textarea form-control mb-3' placeHolder="Reason for decline" value={declineValue} onChange={(e) => { setDeclineValue(e.target.value) }}/>
                                    <p className="text-danger text-start">{err}</p>
                                    <button className='btn btn-theme me-2' onClick={() => { onAdminApprove(false) }}>Decline</button>
                                    <button className='btn btn-theme' onClick={() => { setWithdrawsStatus(false) }}>cancel</button>
                                </div>
                            }

                        </div>


                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default WithdrawModal