import React, { useEffect, useState } from 'react';
import {Dropdown} from 'react-bootstrap'

import dashboard from '../Assets/Images/dashboard.png'

import curency from '../Assets/Images/adminsvg/curency.svg'
import user from '../Assets/Images/adminsvg/user.png'
import cms from '../Assets/Images/adminsvg/settings.svg'
import contact from '../Assets/Images/adminsvg/contact.svg'
import faq from '../Assets/Images/adminsvg/faq.svg'
import faqq from '../Assets/Images/adminsvg/faq-q.svg'
import history from '../Assets/Images/adminsvg/history.svg'
import pwd from '../Assets/Images/adminsvg/pwd.svg'


import { Link, NavLink } from 'react-router-dom';


const Sidebar = () =>{
    const [open,setOpen] = useState(false);
    const [open1,setOpen1] = useState(false);

    const addactive = (e) =>{
        e.target.parentNode.parentNode.classList.add("active");
    }

    return(
        <>
        <ul className='pt-4 sidebarlist'>
            <li><NavLink to="/dashboard"><button><img src={dashboard} className="me-2"/> Dashboard</button></NavLink></li> 
            <li>
               
                <Dropdown show={open? true : false} id="drop_par">
                    <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => setOpen(!open)} style={{display:'flex',alignItems:'center',justifyContent:'space-between'}} >
                      <span><img src={user} className="me-2"/> Users</span> 
                       <span className='fa fa-chevron-down f-12 ms-auto'>  </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu >
                        <Dropdown>
                        <NavLink to="/user">User</NavLink>
                        </Dropdown>
                        <Dropdown><NavLink to="/kyc">KYC</NavLink></Dropdown>
                    </Dropdown.Menu>
                </Dropdown>
                  
            </li>

            <li>
               
               <Dropdown  show={open1? true : false} id="drop_par">
                   <Dropdown.Toggle variant="success" id="dropdown-basic1" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}} onClick={() => setOpen1(!open1)}>
                     <span> <img src={history} className="me-2"/> History</span>
                      <span className='fa fa-chevron-down f-12 ms-auto'>  </span>
                   </Dropdown.Toggle>

                   <Dropdown.Menu  >
                       {/* <Dropdown><NavLink to="/orderhistory">Order History</NavLink></Dropdown> */}
                       <Dropdown><NavLink to="/tradehistory">Trade </NavLink></Dropdown>
                       <Dropdown><NavLink to="/withdraw">Withdraw</NavLink></Dropdown>
                       <Dropdown><NavLink to="/deposit">Deposit</NavLink></Dropdown>
                   </Dropdown.Menu>
               </Dropdown>
                 
            </li>
            {/* <li><NavLink to="/cms"><button><img src={cms} className="me-2"/> CMS</button></NavLink></li>  */}
            {/* <li><NavLink to="/contact"><button><img src={contact} className="me-2"/> Contacts</button></NavLink></li>  */}
            {/* <li><NavLink to="/faqcategory"><button><img src={faq} className="me-2"/> Faq Category</button></NavLink></li>  */}
            <li><NavLink to="/assetlist"><button><img src={faqq} className="me-2 faqq"/> Assets</button></NavLink></li> 
            <li><NavLink to="/adminbank"><button><img src={curency} className="me-2 faqq"/> Bank Details</button></NavLink></li> 

            <li><NavLink to="/changepassword"><button><img src={pwd} className="me-2"/> Change Password</button></NavLink></li> 
            <li><NavLink to="/settings"><button><img src={pwd} className="me-2"/> Settings</button></NavLink></li> 

         
        </ul>
        </>
    )
}

export default Sidebar